import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import getSymbolFromCurrency from "currency-symbol-map";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import userProfile from "@assets/image/userProfile.png";
import { PrimaryText } from "@components";
import Config from "../../config";

const TournamentDetailsCard = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  return (
    <>
      {data?.title ? (
        <div className={cardView}>
          <div className="md:w-[60%] w-full h-auto flex flex-row items-start gap-x-2">
            <div className="w-[70px] h-auto min-w-[65px] min-h-[55px] rounded-md border border-[#969696]">
              <img
                src={
                  data?.image?.data?.attributes?.url
                    ? `${Config.SERVER_HOST}${data?.image?.data?.attributes?.url}`
                    : userProfile
                }
                alt="img"
                className="w-full h-full rounded-md"
              />
            </div>
            <div className="w-auto flex flex-col">
              <PrimaryText className="text-[18px] font-[Cambay] font-semibold uppercase leading-5">
                {data?.title}
              </PrimaryText>
              <PrimaryText className={lightTxt}>
                {data?.description}
              </PrimaryText>

              <div className="w-auto md:flex hidden flex-col">
                {data?.type && (
                  <PrimaryText className={lightTxt}>
                    {t("tournament.type") + data?.type}
                  </PrimaryText>
                )}
                {data?.numberOfPlayers && (
                  <PrimaryText className={lightTxt}>
                    {t("tournament.players") + data?.numberOfPlayers}
                  </PrimaryText>
                )}
                {data?.holesToPlay && (
                  <PrimaryText className={lightTxt}>
                    {t("tournament.holes") + data?.holesToPlay}
                  </PrimaryText>
                )}
              </div>
            </div>
          </div>

          <div className="w-auto h-auto flex flex-col md:items-end items-start md:pl-0 pl-2">
            <div className="w-auto md:hidden flex flex-col mb-2">
              {data?.type && (
                <PrimaryText className={lightTxt}>
                  {t("tournament.type") + data?.type}
                </PrimaryText>
              )}
              {data?.numberOfPlayers && (
                <PrimaryText className={lightTxt}>
                  {t("tournament.players") + data?.numberOfPlayers}
                </PrimaryText>
              )}
              {data?.holesToPlay && (
                <PrimaryText className={lightTxt}>
                  {t("tournament.holes") + data?.holesToPlay}
                </PrimaryText>
              )}
            </div>

            <div className={txtView}>
              <PrimaryText className={txt}>{t("tournament.date")}</PrimaryText>
              <PrimaryText className={lightTxt}>
                {moment(data?.tournamentDate).format("DD/MM/YYYY")}
              </PrimaryText>
            </div>

            {data?.courseId?.courseName && (
              <div className="flex flex-row items-center justify-center gap-x-2">
                <PrimaryText className={txt}>
                  {t("tournament.course")}
                </PrimaryText>
                <PrimaryText className={lightTxt}>
                  {data?.courseId?.courseName}
                </PrimaryText>
              </div>
            )}
            {data?.guestPrice ? (
              <div className="flex flex-row items-center justify-center gap-x-2">
                <PrimaryText className={txt}>
                  {t("tournament.guestPrice")}
                </PrimaryText>
                <PrimaryText className={lightTxt}>
                  {getSymbolFromCurrency("EUR")} {data?.guestPrice}
                </PrimaryText>
              </div>
            ) : (
              <></>
            )}
            {data?.memberPrice ? (
              <div className="flex flex-row items-center justify-center gap-x-2">
                <PrimaryText className={txt}>
                  {t("tournament.memberPrice")}
                </PrimaryText>
                <PrimaryText className={lightTxt}>
                  {getSymbolFromCurrency("EUR")} {data?.memberPrice}
                </PrimaryText>
              </div>
            ) : (
              <></>
            )}
            {
              <button onClick={() => navigate("/tournament")}>
                <KeyboardBackspaceIcon className="text-primary" />

                <PrimaryText className={lightTxt + "text-primary"}>
                  {t("tournament.editSlot")}
                </PrimaryText>
              </button>
            }
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const cardView = `
w-full
h-auto
flex
flex-wrap
justify-between
items-start
gap-y-4
rounded-xl
px-[10px]
py-4
bg-white255
`;
const lightTxt = `
text-md
text-[#969696] 
leading-5
`;
const txtView = `
flex 
flex-row 
items-center 
justify-center 
gap-x-2
`;
const txt = `
text-md
font-[Cambay] 
font-semibold 
text-[#969696] 
leading-6 
text-end
uppercase
`;

export default TournamentDetailsCard;
