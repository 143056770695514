import React from "react";
import tw from "tailwind-styled-components";

function NotFound() {
  return (
    <Root>
      <>
        <h1 className=" mt-24 text-white255 font-bold text-xl">
          Welcome To Santo da Serra Golf Club{" "}
        </h1>
      </>
      <div className="mt-24">
        <h1 className="animate-pulse text-primary font-bold text-3xl">
          Your Page Not Found{" "}
        </h1>
      </div>
    </Root>
  );
}

const Root = tw.div`
w-full 
h-[900px]
flex 
flex-col 
m-auto 
items-center 

`;

export default NotFound;
