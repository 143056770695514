export const CHECK_TEES = "CHECK_TEES";
export const GET_TEETIME_DATA_PAGINATE = "GET_TEETIME_DATA_PAGINATE";
export const UPDATE_TIME = "UPDATE_TIME";
export const UPDATE_DATE = "UPDATE_DATE";
export const TEE_TIME_API_SLUG = "TEE_TIME_API_SLUG";
export const SHOW_LODER = "SHOW_LODER";
export const SHOW_COMPLETE_LODER = "SHOW_COMPLETE_LODER";
export const NETWORK_ERROR = "NETWORK_ERROR";
export const SHOW_TIMER = "SHOW_TIMER";
export const CURRENT_DATE_DATA = "CURRENT_DATE_DATA";
export const REDUX_TEE_TIME_DATa = "REDUX_TEE_TIME_DATa";
export const CACHED_TEE_TIME_DATa = "CACHED_TEE_TIME_DATa";
export const REMOVE_REDUX_TEE_TIME_DATa = "REMOVE_REDUX_TEE_TIME_DATa";
export const API_CALL = "API_CALL";
