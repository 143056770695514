import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { PrimaryText } from "@components";
import { CheckLanguage } from "@utils";
import "moment/locale/fr";
import "moment/locale/de";

export const CachedTimeView = ({ lastCahcedDate }) => {
  const { t } = useTranslation("common");
  const [cacheTime, setCacheTime] = useState("");

  let dateLanguage = CheckLanguage() || "en";
  moment.locale(dateLanguage);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCacheTime(
        moment(lastCahcedDate).locale(dateLanguage).local().fromNow()
      );
    }, 1000);
    return () => clearInterval(intervalId);
  }, [lastCahcedDate]);

  return (
    <div className={"cursor-default"}>
      {cacheTime && (
        <div
          className={`w-fit h-fit flex flex-row items-center justify-start rounded-full gap-x-2 p-[2px] bg-black03 `}
          onClick={() => {}}
        >
          <div className="h-full w-[150px] px-2 flex flex-col items-start justify-center overflow-hidden">
            <PrimaryText className="leading-4 text-white255 opacity-80 text-[12px] pl-[5px]">
              {t("lastCache")}
            </PrimaryText>
            <PrimaryText className="leading-4 text-white255 font-semibold text-[15px] pl-[5px]">
              {cacheTime}
            </PrimaryText>
          </div>
        </div>
      )}
    </div>
  );
};
