import React, { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import tw from "tailwind-styled-components";
import { getClubsData } from "@store";
import ProfileView from "./widgets/profileView";

function ClubDetails() {
  const dispatch = useDispatch();
  const clubData = useSelector((state) => state.clubs.clubsData);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      activeBtn: 3,
      clubsData: clubData ? clubData?.data : [],
    }
  );
  // useEffect(() => {
  //   dispatch(getClubsData());
  // }, []);

  useEffect(() => {
    setState({ clubsData: clubData?.data });
  }, [clubData]);

  return (
    <React.Fragment>
      {state?.clubsData?.length > 0 &&
        state.clubsData?.map((item, index) => (
          <React.Fragment key={`clubd${index}`}>
            <Container>
              <ProfileView props={item} />
            </Container>
          </React.Fragment>
        ))}
    </React.Fragment>
  );
}

const Container = tw.div`
bg-boxBackground
mt-[6px]
mb-[33px]
w-full
rounded-2xl
shadow-xl
drop-shadow-xl
`;

export default ClubDetails;
