import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import tw from "tailwind-styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
import Dialog from "@mui/material/Dialog";
import Tooltip from "@mui/material/Tooltip";
import { Zoom } from "@mui/material";
import { remove } from "@utils";
import { getSelectedPlayerData } from "@store";
import { PrimaryText, CustomButton } from "@components";
import closeBtn from "@assets/image/closeBtn.png";
import "../../containerStyle.css";

function CartModal(props) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.bookings?.addToCartData);

  let addToCartData = cartData?.sort(function (left, right) {
    return moment
      .utc(left?.teeTime?.teeTimeData?.time)
      .diff(moment.utc(right?.teeTime?.teeTimeData?.time));
  });

  const removeItem = (e) => {
    dispatch(
      getSelectedPlayerData({ props: e?.teeTime, price: e?.price, save: true })
    );
    if (addToCartData?.length === 1) {
      props.closeModal();
      localStorage.removeItem("AddToCartsData");
    }
  };

  const goCheckout = () => {
    remove("CompetitonBookData");
    if (window.location.pathname === "/teeTimeBooking") {
      navigate("/teeTimeCheckOut");
    } else {
      navigate("/check-out");
      window.scrollTo(0, 0);
    }
  };

  const Txt = (props) => {
    return (
      <PrimaryText
        className={`text-md font-semibold font-[Cambay] ${
          props.txtColor ? props.txtColor : "text-gray118"
        }`}
      >
        {props.children}
      </PrimaryText>
    );
  };

  const Title = () => {
    return (
      <div className="flex items-center justify-center w-full h-auto my-4">
        <PrimaryText className="text-2xl text-textColor2 font-bold font-[Cambay] text-center">
          {t("teeTimeCart")}
        </PrimaryText>
      </div>
    );
  };

  const PriceTxt = (e) => {
    return (
      <Txt txtColor={"text-textColor2 font-[Cambay] text-[18px]"}>
        {getSymbolFromCurrency(e?.data?.price?.publicRate?.currency) +
          " " +
          e?.data?.price?.publicRate?.amount}
      </Txt>
    );
  };

  const BookingDetails = () => {
    const prices = addToCartData.length
      ? addToCartData.map((a) => a?.price?.publicRate?.amount)
      : "";

    const totalPrice = addToCartData.length
      ? prices.reduce((acc, curr) => acc + curr)
      : "";
    return (
      <React.Fragment>
        {addToCartData?.map((item, index) => (
          <React.Fragment key={`teeTimeCart${index}`}>
            <Line />
            <Container>
              <DetailsView>
                <Txt txtColor={"text-textColor2 text-[18px]"}>
                  {moment(item?.teeTime?.teeTimeData?.time).format(
                    "DD/MM/YYYY - HH:mm"
                  )}
                </Txt>
                <Txt>{item?.teeTime?.teeTimeData?.course}</Txt>
                <Txt>{item?.price?.quantity + " " + t("players")}</Txt>
              </DetailsView>
              <PriceView>
                <Tooltip title={t("removeTees")} arrow>
                  <button onClick={() => removeItem(item)}>
                    <img
                      src={closeBtn}
                      alt={"close"}
                      className="w-[25px] h-[25px] duration-500 hover:transform hover:rotate-90 "
                    />
                  </button>
                </Tooltip>
                <PriceTxt data={item} />
              </PriceView>
            </Container>
            {addToCartData.length - 1 === index ? (
              <>
                <Line />
                <TotlaView>
                  <Txt txtColor={"text-textColor2 text-[18px]"}>
                    {t("total")}
                  </Txt>
                  <Txt txtColor={"text-textColor2 text-[19px]"}>
                    {getSymbolFromCurrency(item?.price?.price?.currency) +
                      " " +
                      totalPrice}
                  </Txt>
                </TotlaView>
              </>
            ) : (
              ""
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      {addToCartData.length > 0 ? (
        <Content>
          <Title />

          <BookingDetails />

          <CustomButton
            onClick={() => goCheckout()}
            value={t("checkout")}
            color="primary"
          />
          <div className="w-full h-auto flex flex-row items-center justify-center gap-x-[2px] mt-[10px]">
            <PrimaryText className="text-md text-white255 font-[Cambay] text-center">
              {t("or")}
            </PrimaryText>
            <button onClick={props.closeModal}>
              <span className="text-[#4f46e5] font-[Cambay] txt-underline-animation">
                {t("continueShopping")}
              </span>
            </button>
          </div>
        </Content>
      ) : (
        <Content className="flex flex-col items-center justify-center">
          <Title />
          <button className="w-full h-auto p-2" onClick={props.closeModal}>
            No Items Available
          </button>
        </Content>
      )}
    </Dialog>
  );
}

const Content = tw.div`
w-full
h-auto 
sm:py-4
sm:px-6
sm2:p-[12px]
p-auto
md:overflow-x-hidden
sm:overflow-x-hidden
overflow-x-auto
overflow-y-auto
custom-scroll

`;
const Container = tw.div`
md:w-[440px]
w-full 
h-auto 
py-2
flex 
flex-row 
items-start 
justify-between
`;
const DetailsView = tw.div`
w-auto 
h-full 
flex 
flex-col 
items-start 
justify-center 
gap-y-2
`;
const PriceView = tw.div`
w-auto 
h-full 
flex 
flex-col 
items-end 
justify-between
gap-y-6
`;
const TotlaView = tw.div`
w-full 
h-auto 
flex 
flex-row 
items-center 
justify-between
mb-4
`;
const Line = tw.div`
w-full
h-[1.3px] 
bg-[#C5C5C5] 
my-2
`;

export default CartModal;
