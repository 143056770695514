import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { clubCoursesData } from "@store";
import { PrimaryText, PlanText } from "@components";
import instaIcon from "@assets/image/instaIcon.png";
import fbIcon from "@assets/image/facebookIcon.png";
import "../../containerStyle.css";

function ProfileView({ props }) {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const facilitiesData = props?.attributes?.facility?.data;

  const [state, setState] = useState({
    deviceType: "",
  });

  useEffect(() => {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|iPod|ipad/i;
    let isMobileDevice = regexp.test(details);
    if (isMobileDevice) {
      setState((prev) => ({ ...prev, deviceType: "Mobile" }));
    } else {
      setState((prev) => ({ ...prev, deviceType: "Desktop" }));
    }
  }, []);

  useEffect(() => {
    dispatch(clubCoursesData(facilitiesData));
  }, [facilitiesData]);

  return (
    <Container>
      <BackImg
        src={props?.attributes?.image?.data?.attributes?.formats?.small?.url}
      />
      <ClubImgView>
        <ClubProfileView>
          <img
            alt="club"
            src={props?.attributes?.logo?.data?.attributes?.url}
            className="w-[100%] h-[100%] rounded-[100%]"
          />
        </ClubProfileView>
      </ClubImgView>
      <div className="w-full h-auto flex flex-col items-center justify-center">
        <PrimaryText className={userNameTxt}>
          {props?.attributes?.name}
        </PrimaryText>
        <div className="w-full h-auto flex felx-row items-center justify-center gap-x-1">
          <PlanText className="text-center opacity-70">
            {props?.attributes?.city}
          </PlanText>
        </div>

        <ContactView>
          <PrimaryText className={contactTxt}>{t("club.contact")}</PrimaryText>
          {state.deviceType === "Mobile" ? (
            <div className="w-fit h-auto flex flex-row items-center justify-center gap-x-[5px] opacity-70">
              <PlanText>{"Tel: "} </PlanText>
              <a
                href={`tel:${props?.attributes?.phone}`}
                target="_blank"
                rel="noreferrer"
                className="text-white255 text-sm text-center font-[Cambay] txt-underline-animation hover:text-[#9590ff] hover:cursor-pointer"
              >
                {props?.attributes?.phone}
              </a>
            </div>
          ) : (
            <PlanText>{"Tel: " + props?.attributes?.phone} </PlanText>
          )}
          <div className="w-fit h-auto flex flex-row items-center justify-center gap-x-[5px] opacity-80">
            <PlanText>{"Email: "} </PlanText>
            <a
              className="font-cambay"
              href={`mailto:${props?.attributes?.email}?subject=The%20K%20Club&body=Welcome%20The%20K%20Club!`}
              target="_top"
            >
              <p className="text-textColor text-sm font-[Cambay] txt-underline-animation hover:text-[#9590ff] hover:cursor-pointer">
                {props?.attributes?.email}{" "}
              </p>
            </a>
          </div>
        </ContactView>

        <SovialView>
          <SocialLogo
            target="_blank"
            href="https://www.facebook.com/GolfSantodaSerra/"
          >
            <img
              src={fbIcon}
              alt="fb"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </SocialLogo>
          <SocialLogo
            target="_blank"
            href="https://www.instagram.com/cgss_golfe/"
          >
            <img
              src={instaIcon}
              alt="ins"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
          </SocialLogo>
        </SovialView>
      </div>
    </Container>
  );
}

const Container = tw.div`
w-full
h-auto 
rounded-xl
pb-[30px]
`;
const BackImg = tw.img`
w-[100%]
h-[170px] 
rounded-t-xl
`;
const ClubImgView = tw.div`
flex 
flex-row 
w-full 
h-[90px] 
mt-[-50px] 
z-10 
justify-between 
items-center
`;
const ClubProfileView = tw.div`
w-[80px] 
h-[90px] 
ml-[19px] 
flex 
justify-center 
items-center
`;
const userNameTxt = `
text-xl 
font-[Cambay] 
font-semibold 
text-textColor
`;
const ContactView = tw.div`
w-full 
h-auto 
flex 
flex-col 
items-center 
justify-center 
mt-[50px]
`;
const contactTxt = `
text-lg
font-[Cambay] 
font-semibold 
text-textColor
ml-[10px]
`;
const SovialView = tw.div`
w-full 
h-auto 
flex 
flex-row 
items-center 
justify-center 
mt-[50px] 
gap-x-4
`;
const SocialLogo = tw.a`
duration-500 
hover:transform 
hover:scale-125
`;

export default ProfileView;
