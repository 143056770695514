import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Zoom } from "@mui/material";
import { PrimaryText } from "@components";

function TimeOutModal(props) {
  return (
    <Dialog
      open={props.openModal}
      onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      <div className=" bg-[#eae9e9]">
        <DialogTitle id="alert-dialog-title">
          Competition Booking Expired
        </DialogTitle>
        <DialogContent>
          <PrimaryText className="text-gray118 text-md  font-[Cambay]">
            Your booking session is expired do you want to continue your
            booking?
          </PrimaryText>
        </DialogContent>
        <div className="w-full h-auto flex flex-row items-center justify-start gap-4 pl-4">
          <DialogActions>
            <Button
              onClick={props.reStartModal}
              className=" shadow-2xl drop-shadow-2xl"
              sx={{ color: "#000000", backgroundColor: "white" }}
            >
              YES
            </Button>
          </DialogActions>
          <DialogActions>
            <Button
              onClick={props.closeModal}
              className=" shadow-2xl drop-shadow-2xl"
              sx={{ color: "#000000", backgroundColor: "white" }}
            >
              NO
            </Button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
}

export default TimeOutModal;
