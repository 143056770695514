import * as types from "./types";

const initialState = {
  headerData: [],
  isModal: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_HEADER_DATA: {
      return {
        ...state,
        headerData: action?.payload?.data?.data,
      };
    }
    case types.CHECK_MODAL: {
      return {
        ...state,
        isModal: action?.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
