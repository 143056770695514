import moment from "moment";
import * as types from "./types";

const initialState = {
  checkTees: null,
  checkTees2: null,
  teesTimer: null,
  reduxTeeTime: null,
  cachedTeeTime: [],
  teeTimeData: [],
  teeTimeDataPaginate: [],
  currentDateTeesData: null,
  nextDateTeesData: null,
  nextDateAfterTomorrowTeesData: null,
  nextDateAfterTomorrow2TeesData: null,
  time: [5, 20],
  date: moment(),
  showLoder: false,
  showCompleteLoder: true,
  isNetworksError: false,
  apiTimer: false,
  teeTimeApiSlug: null,
  apiCall: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REDUX_TEE_TIME_DATa: {
      return {
        ...state,
        reduxTeeTime: action?.payload,
      };
    }

    case types.CACHED_TEE_TIME_DATa: {
      return {
        ...state,
        cachedTeeTime: action?.payload,
      };
    }

    case types.REMOVE_REDUX_TEE_TIME_DATa: {
      return {
        ...state,
        reduxTeeTime: null,
      };
    }

    case types.CHECK_TEES: {
      return {
        ...state,
        checkTees: action?.payload?.data,
      };
    }

    case types.GET_TEETIME_DATA_PAGINATE: {
      if (action?.payload?.data?.success) {
        return {
          ...state,
          teeTimeDataPaginate: action?.payload?.data,
          currentDateTeesData: action?.payload?.data,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case types.UPDATE_TIME: {
      if (
        state.time[0] === action?.payload[0] &&
        state.time[1] === action?.payload[1]
      ) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          time: action?.payload,
        };
      }
    }
    case types.UPDATE_DATE: {
      return {
        ...state,
        date: action?.payload,
      };
    }
    case types.TEE_TIME_API_SLUG: {
      return {
        ...state,
        teeTimeApiSlug: action?.payload,
      };
    }
    case types.SHOW_LODER: {
      return {
        ...state,
        showLoder: action?.payload,
      };
    }
    case types.SHOW_COMPLETE_LODER: {
      if (!action?.payload) {
        return {
          ...state,
          showCompleteLoder: false,
          showLoder: false,
        };
      }
      return {
        ...state,
        showCompleteLoder: true,
      };
    }
    case types.NETWORK_ERROR: {
      return {
        ...state,
        isNetworksError: action?.payload,
      };
    }
    case types.SHOW_TIMER: {
      return {
        ...state,
        apiTimer: action?.payload,
      };
    }
    case types.API_CALL: {
      return {
        ...state,
        apiCall: action?.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
