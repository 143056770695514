import * as types from "./types";

const initialState = {
  time: null,
  usdPrice: 1,
  bgImage: "",
  county: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COUNT_DOWN: {
      return {
        ...state,
        time: action?.payload,
      };
    }
    case types.GET_COUNTY: {
      return {
        ...state,
        county: action?.payload,
      };
    }
    case types.GET_USD_PRICE: {
      return {
        ...state,
        usdPrice: action?.payload,
      };
    }
    case types.GET_BG_IMAGE: {
      return {
        ...state,
        bgImage: action?.payload,
        time: action?.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
