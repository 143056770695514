import React, { useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import PersonIcon from "@mui/icons-material/Person";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../componentStyle.css";

function CustomInput(props) {
  const [showPassword, setShowPassword] = useState(false);

  const changeType = () => {
    setShowPassword(!showPassword);
  };

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#535754ad",
      },
    },
  });

  return (
    <div className="w-full h-auto">
      <div className="relative text-gray-600 focus:border-gray-500 w-full h-auto mt-4 border rounded-full">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2 font-[Cambay]">
          <div className="p-1 focus:outline-none focus:shadow-outline">
            <ThemeProvider theme={theme}>
              {props.title === "Username" ? (
                <AccountBoxIcon fontSize="medium" color="secondary" />
              ) : props.title === "Email" ? (
                <EmailIcon fontSize="medium" color="secondary" />
              ) : props?.title === "Password" ||
                props?.title === "Confirm Password" ? (
                <VpnKeyIcon fontSize="medium" color="secondary" />
              ) : props?.title === "First Name" ||
                props?.title === "Last Name" ? (
                <PersonIcon fontSize="medium" color="secondary" />
              ) : (
                ""
              )}
            </ThemeProvider>
          </div>
        </span>
        <input
          className={`w-full py-2 text-md bg-gray-200 rounded-full focus:outline-none focus:bg-white border focus:border-gray-500 ${
            props.error ? "border-red-500" : ""
          } ${!props.secureText ? "pl-10 pr-2" : "px-10"}`}
          autoComplete="off"
          role="presentation"
          type={`${props.secureText && !showPassword ? "password" : "text"}`}
          placeholder={props?.title}
          value={props.value}
          onChange={props.onChange}
          maxLength={props.maxLength}
        />

        {props.secureText ? (
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 font-[Cambay]">
            <div className="p-1 focus:outline-none focus:shadow-outline">
              <ThemeProvider theme={theme}>
                <button onClick={() => changeType()}>
                  {" "}
                  {showPassword ? (
                    <VisibilityOutlinedIcon
                      fontSize="medium"
                      color="secondary"
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      fontSize="medium"
                      color="secondary"
                    />
                  )}
                </button>
              </ThemeProvider>
            </div>
          </span>
        ) : (
          <></>
        )}
      </div>

      <p className="text-red-500 text-xs italic">
        {props.error
          ? props.passwordError
            ? "Password and Confirm Password does not match"
            : props.errorMessage
            ? props.errorMessage
            : "Please fill out this field."
          : ""}
      </p>
    </div>
  );
}

export default CustomInput;
