import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import tw from "tailwind-styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
import CircularProgress from "@mui/material/CircularProgress";
import { PrimaryText } from "@components";
import {
  allCompetitions,
  allTournament,
  singleTournament,
  competitionLoader,
} from "@store";
import userProfile from "@assets/image/userProfile.png";
import Config from "../../../config";
import "../../containerStyle.css";

function EnterCard() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { competitionId } = useParams();
  const allCompetitionsData = useSelector(
    (state) => state.competition?.allCompetitions
  );
  const allTournaments = useSelector(
    (state) => state.competition?.allTournaments
  );
  const singleTournaments = useSelector(
    (state) => state.competition?.singleTournaments
  );
  const competitionsTeeTime = useSelector(
    (state) => state.competition?.competitionsTeeTime
  );
  const isLoader = useSelector((state) => state.competition?.tournamentLoader);

  const [state, setState] = useState({
    loader: false,
    confirmId: null,
    tournamentsList: [],
  });

  useEffect(() => {
    if (allCompetitionsData?.length) {
    } else {
      dispatch(allCompetitions());
    }
  }, []);

  useEffect(() => {
    dispatch(competitionLoader(true));
    dispatch(allTournament()).then(async () => {
      await dispatch(competitionLoader(false));
    });
  }, [competitionId]);

  useEffect(() => {
    if (allTournaments?.length) {
      let currentTimeFilter = allTournaments?.filter(function (date) {
        if (date) {
          var dt = new Date(Date.now() - 3600 * 1000 * 24);
          var df = new Date(date?.tournamentDate);
          if (df.getTime() > dt.getTime()) return df;
        }
      });

      let filterdTournament = currentTimeFilter?.filter((res) => {
        return res?.competitionId?.id === Number(competitionId);
      });

      let sortedTournament = filterdTournament?.sort(function (a, b) {
        return new Date(a?.tournamentDate) - new Date(b?.tournamentDate);
      });

      setState((prev) => ({ ...prev, tournamentsList: sortedTournament }));

      if (
        competitionsTeeTime?.teeTimes?.length &&
        singleTournaments?.tournament?.tournamentDate !== null &&
        moment(competitionsTeeTime?.teeTimes[0]?.time).isSame(
          singleTournaments?.tournament?.tournamentDate,
          "day"
        ) &&
        sortedTournament?.length &&
        sortedTournament?.filter(
          (e) =>
            e?.id === singleTournaments?.tournament?.id &&
            e?.tournamentDate === singleTournaments?.tournament?.tournamentDate
        ).length
      ) {
      } else {
        sortedTournament?.length &&
          dispatch(singleTournament(sortedTournament[0]?.id));
      }
    }
  }, [allTournaments]);

  const enterNow = (e) => {
    dispatch(singleTournament(e?.id)).then((res) => {
      if (res?.payload?.data && res?.payload?.status === 200) {
        setState((prev) => ({ ...prev, loader: false }));
      } else {
        setState((prev) => ({ ...prev, loader: false }));
      }
    });
    setState((prev) => ({ ...prev, loader: true, confirmId: e?.id }));
  };

  return (
    <Root>
      {state.tournamentsList?.length > 0 &&
        state.tournamentsList?.map((item, index) => (
          <React.Fragment key={`competition${index}`}>
            <Component>
              <Heading>
                <div className="w-[75px] h-[60px] min-h-[55px] min-w-[65px] rounded-md">
                  <img
                    src={
                      item?.attributes?.image?.data?.attributes?.url
                        ? `${Config.SERVER_HOST}${item?.attributes?.image?.data?.attributes?.url}`
                        : userProfile
                    }
                    alt="img"
                    className="w-full h-full rounded-md"
                  />
                </div>
                <div className="w-full flex flex-col">
                  <PrimaryText className="text-md font-[Cambay] font-semibold text-white255 uppercase leading-5">
                    {item?.title}
                  </PrimaryText>
                  <PrimaryText className="text-md font-[Cambay] font-semibold text-white255 uppercase leading-5">
                    {moment(item?.tournamentDate).format("DD/MM/YYYY")}
                  </PrimaryText>
                </div>
              </Heading>
              <Info>
                {item?.attributes?.description && (
                  <PrimaryText className={txt}>{item?.description}</PrimaryText>
                )}
                {item?.type && (
                  <PrimaryText className={txt}>
                    {t("tournament.type") + item?.type}
                  </PrimaryText>
                )}
                {item?.courseId?.courseName && (
                  <PrimaryText className={txt}>
                    {t("tournament.course") + item?.courseId?.courseName}
                  </PrimaryText>
                )}
                {item?.numberOfPlayers && (
                  <PrimaryText className={txt}>
                    {t("tournament.players") + " " + item?.numberOfPlayers}
                  </PrimaryText>
                )}
                {item?.holesToPlay && (
                  <PrimaryText className={txt}>
                    {t("tournament.holes") + " " + item?.holesToPlay}
                  </PrimaryText>
                )}
                {item?.guestPrice && (
                  <PrimaryText className={txt}>
                    {t("tournament.guest") +
                      getSymbolFromCurrency("EUR") +
                      " " +
                      item?.guestPrice}
                  </PrimaryText>
                )}
              </Info>
              <Btn
                onClick={() => enterNow(item)}
                disabled={isLoader}
                className={`${
                  isLoader
                    ? " opacity-70 hover:cursor-wait"
                    : "hover:bg-white255a"
                }`}
              >
                {state.confirmId === item?.id && state.loader && isLoader ? (
                  <CircularProgress sx={{ color: "#121037" }} size={20} />
                ) : (
                  <span className="text-md font-semibold text-textColor2 uppercase font-[Cambay]">
                    {t("tournament.enterNow")}
                  </span>
                )}
              </Btn>
            </Component>
          </React.Fragment>
        ))}
    </Root>
  );
}

const Root = tw.div`
w-full 
h-auto 
flex 
lg2:flex-col flex-row
overflow-x-scroll
overflow-y-hidden
custom-scroll
gap-4 
items-center 
lg2:justify-center justify-start 
`;

const Component = tw.div`
h-auto
w-full
max-w-[330px]
min-w-[250px]
flex
flex-col
gap-y-3
rounded-xl
bg-white255b
px-[10px]
py-4
`;
const Heading = tw.div`
w-full 
h-auto 
flex 
flex-row 
gap-x-2
`;
const Info = tw.div`
w-full 
h-auto 
flex 
flex-col 
items-start 
justify-start 
gap-y-[5px]
`;
const txt = `
text-sm 
text-white255 
opacity-80 
leading-4
`;
const Btn = tw.button`
w-full 
h-auto 
rounded-full 
text-center 
px-[5px]
py-[5px] 
bg-white255
mt-2
`;

export default EnterCard;
