import React, { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import tw from "tailwind-styled-components";
import {
  RoundHole,
  HeadingText,
  CustomButton,
  SecondaryText,
} from "@components";
import {
  apiCall,
  getTees,
  updateDate,
  networkError,
  activePlayer,
  teeTimeApiSlug,
  getReduxTeeTime,
  getCachedTeeTime,
  showCompleteLoder,
  getFacilitiesData,
} from "@store";
import { CheckLanguage } from "@utils";
import DatePickers from "./widgets/datePicker";
import "./style.css";
import "../containerStyle.css";

function FilterBox() {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const updatedDate = useSelector((state) => state.teeTime.date);
  const clubData = useSelector((state) => state.clubs.clubsData);
  const facilitiesData = clubData?.data?.[0]?.attributes?.facility?.data;
  const courseData = facilitiesData?.attributes?.courses?.data;
  const apiTimer = useSelector((state) => state.setting.time);
  const cachedTime = apiTimer?.data?.data?.attributes?.cachingTime;
  const cachedTeeTime = useSelector((state) => state.teeTime.cachedTeeTime);
  const isApiCall = useSelector((state) => state.teeTime.apiCall);
  const activePlayers = useSelector((state) => state.courses.activePlayer);
  const isCompleteLoading = useSelector(
    (state) => state.teeTime.showCompleteLoder
  );
  const teeTimeApiSlugdata = useSelector(
    (state) => state.teeTime.teeTimeApiSlug
  );

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      hole: 18,
      players: 1,
      date: updatedDate || moment(),
      lastCachedMinutes: cachedTime || 15,
    }
  );

  useEffect(() => {
    if (updatedDate) {
      setState({
        date: updatedDate,
      });
    }
  }, [updatedDate]);

  let apiData = {
    holes: state.hole,
    players: 1,
    bookingDate: state.date,
    facilitiesId: facilitiesData?.id,
  };

  useEffect(() => {
    let filterDate = cachedTeeTime?.filter((e) => {
      return e?.date === moment(updatedDate).format("DDMMYYYY");
    });

    if (filterDate?.length) {
      var lastMins = moment(filterDate[0]?.lastCacheAt, "DD.MM.YYYY HH:mm:ss");

      if (
        moment().local().diff(lastMins, "minutes") >= state.lastCachedMinutes
      ) {
        dispatch(showCompleteLoder(true));
        fetchTeeTime();
      } else {
        dispatch(getReduxTeeTime(filterDate[0]?.teeTime));
        dispatch(showCompleteLoder(false));
      }
    } else {
      fetchTeeTime();
    }
  }, [facilitiesData]);

  const fetchTeeTime = async () => {
    if (facilitiesData?.id && courseData?.length) {
      let completeData = [];
      await Promise.all(
        courseData.map(async (item, i) => {
          await new Promise((resolve) => {
            setTimeout(resolve, 3000 * i);
          });
          const res = await dispatch(
            getTees({
              apiData: apiData,
              courseId: item?.attributes?.courseId,
            })
          );
          if (res?.payload?.data && res?.payload?.status === 200) {
            let data = res?.payload?.data?.data;
            completeData = completeData.concat(data?.teeTimes);

            let date = moment(apiData.bookingDate).format("DDMMYYYY");
            if (cachedTeeTime?.find((e) => e?.date == date)) {
              let arr = [...cachedTeeTime];
              let index = arr?.findIndex((obj) => obj?.date == date);
              if (index !== -1) {
                arr[index] = {
                  date: date,
                  teeTime: completeData,
                  lastCacheAt: moment().local(),
                };
              }
              dispatch(getCachedTeeTime(arr));
            } else {
              dispatch(
                getCachedTeeTime(
                  cachedTeeTime?.concat({
                    date: date,
                    teeTime: completeData,
                    lastCacheAt: moment().local(),
                  })
                )
              );
            }
            dispatch(getReduxTeeTime(completeData));
          }
        })
      );
      dispatch(showCompleteLoder(false));
      dispatch(apiCall(false));
    }
  };

  useEffect(() => {
    if (facilitiesData?.id) {
      dispatch(getFacilitiesData(facilitiesData?.id));
    }
  }, [facilitiesData]);

  useEffect(() => {
    setState({ date: moment(teeTimeApiSlugdata?.apiData?.bookingDate) });
    if (
      teeTimeApiSlugdata?.apiData?.holes &&
      teeTimeApiSlugdata?.apiData?.players
    ) {
      setState({
        hole: teeTimeApiSlugdata?.apiData?.holes,
        players: teeTimeApiSlugdata?.apiData?.players,
      });
    }
  }, [teeTimeApiSlugdata]);

  useEffect(() => {
    if (isApiCall && state.date === updatedDate) {
      let filterDate = cachedTeeTime?.filter((e) => {
        return e?.date === moment(updatedDate).format("DDMMYYYY");
      });

      if (filterDate?.length) {
        var lastMins = moment(
          filterDate[0]?.lastCacheAt,
          "DD.MM.YYYY HH:mm:ss"
        );

        if (
          moment().local().diff(lastMins, "minutes") >= state.lastCachedMinutes
        ) {
          dispatch(showCompleteLoder(true));
          fetchTeeTime();
        } else {
          dispatch(getReduxTeeTime(filterDate[0]?.teeTime));
          dispatch(showCompleteLoder(false));
          dispatch(apiCall(false));
        }
      } else {
        dispatch(showCompleteLoder(true));
        fetchTeeTime();
      }
    }
  }, [isApiCall, state.date, updatedDate]);

  const changeDate = (e) => {
    if (!isCompleteLoading) {
      setState({ date: e });
    }
  };
  const changeHole = (e) => {
    if (!isCompleteLoading) {
      setState({
        hole: e,
      });
    }
  };
  const changePlayer = (e) => {
    if (!isCompleteLoading) {
      setState({
        players: e,
      });
      dispatch(activePlayer(e));
    }
  };

  useEffect(() => {
    if (activePlayers)
      setState({
        players: activePlayers,
      });
  }, [activePlayers]);

  useEffect(() => {
    if (updatedDate) {
      setState({
        date: updatedDate,
      });
    }
  }, [updatedDate]);

  const submit = () => {
    dispatch(showCompleteLoder(true));
    dispatch(updateDate(state.date));
    dispatch(teeTimeApiSlug({ apiData }));
    dispatch(networkError(false));
    dispatch(apiCall(true));
  };

  const PlayerHoleView = (props) => {
    return (
      <RoundHole
        onClick={() => changePlayer(props.value)}
        btnClass={
          state.players === props.value
            ? "bg-textColor hover:cursor-default"
            : `bg-holeBG border-[0.5px] border-textColor ${
                isCompleteLoading
                  ? "hover:cursor-wait"
                  : "hover:cursor-pointer hover:opacity-70"
              }`
        }
        active={state.players === props.value ? true : false}
      >
        {props.value}
      </RoundHole>
    );
  };

  const HeadingView = () => {
    let dateLanguage = CheckLanguage() || "en";
    return (
      <div className=" hover:cursor-default">
        <HeadingText>
          {state.date
            ? moment(state.date).locale(dateLanguage).format("ddd, D MMM")
            : moment().locale(dateLanguage).format("ddd, D MMM")}
        </HeadingText>
      </div>
    );
  };

  return (
    <Container>
      <div className={DateView}>
        <SecondaryText>{t("date")}</SecondaryText>
        <HeadingView />
        <div className="h-auto w-auto">
          <DatePickers
            newDate={(e) => changeDate(e)}
            value={state.date}
            disabled={isCompleteLoading}
          />
        </div>
      </div>
      <SecondaryText>{t("holes")}</SecondaryText>
      <div className={HoleView}>
        <RoundHole
          onClick={() => changeHole(9)}
          btnClass={
            state.hole === 9
              ? "bg-textColor hover:cursor-default"
              : `bg-holeBG border-[0.5px] border-textColor ${
                  isCompleteLoading
                    ? "hover:cursor-wait"
                    : "hover:cursor-pointer hover:opacity-70"
                }`
          }
          active={state.hole === 9 ? true : false}
        >
          9
        </RoundHole>
        <RoundHole
          onClick={() => changeHole(18)}
          btnClass={
            state.hole === 18
              ? "bg-textColor hover:cursor-default"
              : `bg-holeBG border-[0.5px] border-textColor ${
                  isCompleteLoading
                    ? "hover:cursor-wait"
                    : "hover:cursor-pointer hover:opacity-70"
                }`
          }
          active={state.hole === 18 ? true : false}
        >
          18
        </RoundHole>
      </div>
      <SecondaryText>{t("players")}</SecondaryText>
      <div className={HoleView2}>
        <PlayerHoleView value={1} />
        <PlayerHoleView value={2} />
        <PlayerHoleView value={3} />
        <PlayerHoleView value={4} />
      </div>
      <CustomButton
        onClick={() => submit()}
        btnLoading={isCompleteLoading}
        value={t("refreshTeeTime")}
        disabled={isCompleteLoading}
      />
    </Container>
  );
}

const Container = tw.div`
p-4
w-[100%]
h-auto
bg-boxBackground
mt-[6px]
rounded-2xl
shadow-xl
drop-shadow-xl
`;
const DateView = `
flex
flex-row
items-center
justify-between
mb-[5px]
`;
const HoleView = `
mt-[5px]
mb-[10px]
flex
flex-row
h-[40px]
w-[42%]
items-center
justify-between
gap-x-2
`;
const HoleView2 = `
mt-[5px]
mb-[20px]
flex
flex-row
h-[40px]
items-center
justify-between
`;

export default FilterBox;
