import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Zoom } from "@mui/material";
import { PrimaryText } from "@components";

function CustomModal(props) {
  return (
    <Dialog
      open={props.openModal}
      onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <PrimaryText className="text-gray118 text-md  font-[Cambay]">
          {props.description}
        </PrimaryText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeModal} sx={{ color: "#000000" }}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function BookingAlertMOdal(props) {
  return (
    <Dialog
      fullScreen
      open={props.openModal}
      onClose={props.closeModal}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <div className="bg-[#464646ba] py-6 pt-40 px-4 w-full h-full d-flex justify-center items-center gap-2">
        <div className="w-full h-[80px] flex justify-center items-center">
          <svg className="w-16 h-16 bg-transparent border-4 animate-spin rounded-full border-t-[white] border-primary" />
        </div>
        <div className="text-center">
          <PrimaryText className="text-white text-[19px]">
            {
              "Please wait for while, don't refresh we are booking your tee time"
            }
          </PrimaryText>
        </div>
      </div>
    </Dialog>
  );
}

function EmptyCartModal(props) {
  const { t } = useTranslation("common");
  return (
    <Dialog
      open={props.openModal}
      // onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      <div className="bg-white py-8 px-6 w-full h-full flex justify-center items-center gap-4 flex-col text-center">
        <div className="text-center">
          <PrimaryText className="text-black font-[Cambay] text-[21px]">
            {props.errorMessage}
          </PrimaryText>
        </div>
        <button
          onClick={props.closeModal}
          className="px-2 bg-primary hover:bg-primary2 text-white font-semibold py-2 rounded-md"
        >
          {t("successModal.goBack")}
        </button>
      </div>
    </Dialog>
  );
}

export { CustomModal, EmptyCartModal, BookingAlertMOdal };
