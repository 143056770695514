import * as types from "./types";

const initialState = {
  clubCoursData: [],
  activeCours: "9 holes",
  activePlayer: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLUB_COURSES_DATA: {
      return {
        ...state,
        clubCoursData: action?.payload,
      };
    }
    case types.ACTIVE_COURSES: {
      return {
        ...state,
        activeCours: action?.payload,
      };
    }
    case types.ACTIVE_PLAYER: {
      return {
        ...state,
        activePlayer: action?.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
