import React, { useReducer, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import moment from "moment";
import tw from "tailwind-styled-components";
import getSymbolFromCurrency from "currency-symbol-map";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { FilterBox, ClubDetails } from "@containers";
import { WeatherView, PrimaryText } from "@components";
import {
  apiCall,
  updateDate,
  activePlayer,
  networkError,
  showCompleteLoder,
  removeReduxTeeTime,
  getSelectedPlayerData,
} from "@store";
import { CheckLanguage, filterTeeTimeData } from "@utils";
import TeeTimeView from "./widgets/teeTimeView";
import CartModal from "./widgets/addToCartModal";
import { CachedTimeView } from "./widgets/cachedTimeView";
import "../containerStyle.css";

function TeeTimeBooking() {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const addToCartData = useSelector((state) => state.bookings?.addToCartData);
  const updatedDate = useSelector((state) => state.teeTime.date);
  const isNetworkError = useSelector((state) => state.teeTime.isNetworksError);
  const reduxTeeTime = useSelector((state) => state.teeTime.reduxTeeTime);
  const cachedTeeTime = useSelector((state) => state.teeTime.cachedTeeTime);
  const activePlayers = useSelector((state) => state.courses.activePlayer);
  const teeTimeApiSlugdata = useSelector(
    (state) => state.teeTime.teeTimeApiSlug
  );
  const isCompleteLoading = useSelector(
    (state) => state.teeTime.showCompleteLoder
  );
  const apiTimer = useSelector((state) => state.setting.time);
  const cachedTime = apiTimer?.data?.data?.attributes?.cachingTime;

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      data: [],
      loading: false,
      cartModal: false,
      lastCachedHours: 0,
      activeDate: moment(),
      lastCachedMinutes: cachedTime || 60,
      lastCahcedDate: "",
    }
  );

  useEffect(() => {
    setState({ activeDate: moment(teeTimeApiSlugdata?.apiData?.bookingDate) });
  }, [teeTimeApiSlugdata]);

  useEffect(() => {
    let localdata = JSON.parse(localStorage.getItem("LocalCartData"));
    if (localdata?.length && !addToCartData?.length) {
      dispatch(
        getSelectedPlayerData({ localdata: localdata, localSave: true })
      );
    }
  }, [addToCartData]);

  useEffect(() => {
    if (updatedDate) {
      setState({
        activeDate: updatedDate,
      });
    }
  }, [updatedDate]);

  useEffect(() => {
    const dateFilter = reduxTeeTime?.filter((res) => {
      return moment(res?.time).isSame(state.activeDate, "day");
    });
    const currentTimeFilter = _.filter(dateFilter, function (e) {
      if (moment(e?.time).format("D") === moment().format("D")) {
        return moment(e?.time).format("HHmm") > moment().format("HHmm");
      } else {
        return e;
      }
    });
    const playerFilter = currentTimeFilter?.filter((res) => {
      return res?.products[0]?.available >= activePlayers;
    });

    const filterTeesData = filterTeeTimeData(playerFilter);
    if (filterTeesData?.length) {
      setState({ data: filterTeesData });
    } else {
      setState({ data: [] });
    }
  }, [reduxTeeTime, state.activeDate, activePlayers]);

  useEffect(() => {
    let filterDate = cachedTeeTime?.filter((e) => {
      return e?.date === moment(state.activeDate).format("DDMMYYYY");
    });

    if (filterDate?.length) {
      var now = moment(new Date());
      var end = moment(filterDate[0]?.lastCacheAt).local();
      var duration = moment.duration(now.diff(end));
      var hours = duration.hours();
      var minutes = duration.minutes();

      setState({
        lastCachedHours: hours,
        lastCachedMinutes: minutes,
        lastCahcedDate: filterDate[0]?.lastCacheAt,
      });
    } else {
      setState({
        lastCachedHours: 0,
        lastCachedMinutes: 15,
        lastCahcedDate: moment(),
      });
    }
  }, [cachedTeeTime, state.activeDate, reduxTeeTime]);

  const selectedDate = moment(teeTimeApiSlugdata?.apiData?.bookingDate);
  const nextDate = moment(teeTimeApiSlugdata?.apiData?.bookingDate).add(
    1,
    "day"
  );
  const newDateAfterTomorrow = moment(
    teeTimeApiSlugdata?.apiData?.bookingDate
  ).add(2, "day");
  const newDateAfterTomorrow2 = moment(
    teeTimeApiSlugdata?.apiData?.bookingDate
  ).add(3, "day");

  const closeModal = () => {
    setState({ cartModal: false });
  };

  const ShowMessage = (props) => {
    return (
      <div className="w-full h-full flex justify-center items-start mt-[40px] mb-[40px] py-[15px]">
        <PrimaryText
          className={"center text-2xl text-textColor animate-pulse text-start "}
        >
          {props.title}
        </PrimaryText>
      </div>
    );
  };

  const changeDate = (newDate) => {
    if (
      moment(state.activeDate).format("DD-MM-YYYY") ===
      moment(newDate).format("DD-MM-YYYY")
    ) {
    } else {
      dispatch(networkError(false));
      dispatch(removeReduxTeeTime());
      dispatch(updateDate(newDate));
      dispatch(showCompleteLoder(true));
      dispatch(activePlayer(teeTimeApiSlugdata?.apiData?.players || 1));
      setState({ activeDate: moment(newDate), loading: true });
      setTimeout(() => {
        setState({ loading: false });
        dispatch(apiCall(true));
      }, 300);
    }
  };

  const DateView = (props) => {
    let dateLanguage = CheckLanguage() || "en";
    const dayName = moment(props?.title)
      .locale(dateLanguage)
      .format("ddd, D MMM");
    return (
      <DateBtn
        onClick={() => changeDate(props?.title)}
        className={`${
          moment(state.activeDate).format("DD-MM-YYYY") ===
          moment(props?.title).format("DD-MM-YYYY")
            ? "bg-primary hover:cursor-default"
            : `bg-black02 hover:opacity-70`
        }`}
      >
        <p className={`text-sm font-[Cambay] text-center text-white255`}>
          {dayName}
        </p>
      </DateBtn>
    );
  };

  const CartView = () => {
    return (
      <div
        className={`${
          addToCartData?.length < 1 ? "cursor-not-allowed" : "cursor-default"
        }`}
      >
        <div
          className={`w-fit h-[40px] flex flex-row items-center justify-start rounded-full gap-x-2 bg-black03 ${
            addToCartData?.length ? "hover:cursor-pointer" : ""
          } `}
          onClick={() =>
            setState({ cartModal: addToCartData?.length ? true : false })
          }
        >
          <IconButton
            disabled={addToCartData?.length ? false : true}
            sx={{ bgcolor: "#ffffff60" }}
          >
            <ShoppingCartIcon fontSize="medium" sx={{ color: "white" }} />
          </IconButton>
          {addToCartData?.length > 0 && (
            <div className="h-full w-[80px] flex flex-col items-start justify-center overflow-hidden">
              <PrimaryText className="leading-4 text-white255 opacity-80 text-[13px]">
                {addToCartData?.length + " " + t("select")}
              </PrimaryText>
              <PrimaryText className="leading-4 text-white255 font-medium text-[17px]">
                {getSymbolFromCurrency(
                  addToCartData[0]?.price?.price?.currency
                ) +
                  " " +
                  totalPrice}
              </PrimaryText>
            </div>
          )}
        </div>
        <CartModal openModal={state.cartModal} closeModal={closeModal} />
      </div>
    );
  };

  const SkeletonLoader = () => {
    const TxtLoader = () => {
      return (
        <div className="w-[128px] h-[25px]">
          <Skeleton
            variant="text"
            sx={{ bgcolor: "#00000096" }}
            width={128}
            height={25}
          />
        </div>
      );
    };
    const BoxLoader = () => {
      return (
        <div className="w-[90px] h-[67px] rounded-lg">
          <Skeleton
            variant="rounded"
            sx={{ bgcolor: "#ffffff5c", borderRadius: "10px" }}
            animation="wave"
            width={90}
            height={67}
          />
        </div>
      );
    };

    return (
      <LoaderView>
        <AnimationTeeTime>
          <div className="w-full h-full flex flex-col items-center gap-2">
            <TxtLoader />
            <AnimatedBox>
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
            </AnimatedBox>
          </div>

          <div className="w-full h-full xl3:flex hidden flex-col items-center gap-2">
            <TxtLoader />
            <AnimatedBox>
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
            </AnimatedBox>
          </div>
        </AnimationTeeTime>
      </LoaderView>
    );
  };

  const prices = addToCartData.length
    ? addToCartData.map((a) => a?.price?.publicRate?.amount)
    : "";
  const totalPrice = addToCartData.length
    ? prices.reduce((acc, curr) => acc + curr)
    : "";

  return (
    <div className={mainView}>
      <div className={teeTimeView}>
        <div className={courseView}>
          <FilterBox />
          <WeatherView />
        </div>
        <div
          className={`${teeTimeBookingView}${
            !isCompleteLoading && !state.data?.length
              ? `xl2:w-full md3:w-[54%] w-full xl2:px-4 px-0`
              : `md:px-4 px-0 xl2:w-[54%] w-full`
          }`}
        >
          {
            <div className="w-full h-auto flex flex-row items-start justify-between gap-[4px] mx-[2px] px-[2px] mt[3px]">
              <div className={dateView}>
                <DateView title={selectedDate} />
                <DateView title={nextDate} />
                <DateView title={newDateAfterTomorrow} />
                <DateView title={newDateAfterTomorrow2} />
              </div>
              <div className="h-auto w-auto flex md:flex-row flex-col gap-2">
                {!isCompleteLoading && (
                  <CachedTimeView
                    lastCachedHours={state.lastCachedHours}
                    lastCachedMinutes={state.lastCachedMinutes}
                    lastCahcedDate={state.lastCahcedDate || moment().local()}
                  />
                )}
                {CartView()}
              </div>
            </div>
          }
          {isNetworkError ? (
            <ShowMessage title={"Failed To Load TeeTime Data !"} />
          ) : isCompleteLoading || state.loading ? (
            <SkeletonLoader />
          ) : state.data?.length ? (
            <Container>
              <TeeTimeView data={state.data} />
            </Container>
          ) : (
            <ShowMessage title={t("noTeeTimeMessage")} />
          )}
        </div>

        <div className={clubView}>
          <div className="w-full h-full flex xl2:justify-end justify-center rounded-lg">
            <ClubDetails />
          </div>
        </div>
      </div>
    </div>
  );
}

const mainView = `
w-full 
h-auto
px-4
pt-4
flex
items-between
justify-center
lg2:flex-row
flex-col
`;
const teeTimeView = `
h-auto
w-full
flex
xl2:flex-row
flex-col
xl2:items-start
items-center
justify-between
`;
const courseView = `
w-full
max-w-[355px] 
h-auto 
md:flex
flex-col 
items-center
`;
const teeTimeBookingView = `
h-auto 
md2:my-0 
my:-[40px]
justify-center
items-center
xl2:pt-[6px]
pt-6
`;
const clubView = `
w-full
max-w-[355px]
h-auto
md:flex
flex-row
items-between
justify-between
rounded-lg
`;
const Container = tw.div`
w-auto
h-auto
mx-[2px]
px-[2px]
mt-[3px]
mb-[33px]
`;
const dateView = `
w-full 
h-auto 
flex 
flex-wrap 
itmes-center
gap-2
`;
const DateBtn = tw.button`
w-[90px] 
h-auto 
rounded-2xl 
flex 
items-center 
justify-center 
pt-[6px]
pb-[1px]
`;

const LoaderView = tw.div`
w-full 
lg2:h-[470px] h-fit 
flex 
flex-col 
justify-center 
items-center 
gap-2 
rounded-lg 
p-2 
overflow-hidden
mb-8
`;
const AnimationTeeTime = tw.div`
flex 
flex-row 
items-center 
justify-around 
w-full 
h-full 
rounded-lg 
gap-2
`;
const AnimatedBox = tw.div`
w-full 
h-full 
flex 
flex-wrap 
items-center 
justify-center 
gap-2 
border-[2px]
border-textColor 
rounded-2xl 
py-2
`;

export default TeeTimeBooking;
