export const countries = [
  { label: "Carlow" },
  {
    label: "Cavan",
  },
  { label: "Clare" },
  {
    label: "Cork",
  },
  { label: "Donegal" },
  { label: "Dublin" },
  { label: "Galway" },
  { label: "Kerry" },
  { label: "Kildare" },
  { label: "Kilkenny" },
  { label: "Laois" },
  { label: "Leitrim" },
  {
    label: "Limerick",
  },
  { label: "Longford" },
  { label: "Louth" },
  { label: "Mayo" },
  {
    label: "Meath",
  },
  { label: "Monaghan" },
  { label: "Offaly" },
  { label: "Roscommon" },
  { label: "Sligo" },
  { label: "Tipperary" },
  { label: "Waterford" },
  { label: "Westmeath" },
  { label: "Wexford " },
  { label: "Wicklow" },
];
