import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import tw from "tailwind-styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import { get } from "@utils";
import { PrimaryText, TostMessage } from "@components";
import { getCountDown, getListingBookings } from "@store";
import playerImg from "@assets/image/user.png";

function PlayerView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const [state, setState] = useState({
    isLoding: false,
    availableSlot: 0,
    alertMessage: false,
    playersPriceList: [],
    alertMessageTxt: null,
    teeTimeISBooked: false,
  });
  const apiTimer = useSelector((state) => state.setting.time);
  const playerTypeId = apiTimer?.data?.data?.attributes;

  const prices = state.playersPriceList?.length
    ? state.playersPriceList?.map((a) => a?.activePrice)
    : "";
  const totalPrice = state.playersPriceList?.length
    ? prices?.reduce((acc, curr) => acc + curr)
    : "";

  // useEffect(() => {
  //   dispatch(getCountDown());
  // }, []);

  useEffect(() => {
    const tournamentDetails = get("CompetitonBookData");
    if (tournamentDetails !== null) {
      const data = JSON.parse(get("CompetitonBookData"));
      let gPrice = data?.competitonBookData?.data?.guestPrice
        ? data?.competitonBookData?.data?.guestPrice
        : 0;
      let mPrice = data?.competitonBookData?.data?.memberPrice
        ? data?.competitonBookData?.data?.memberPrice
        : 0;

      let arr = Array.apply(
        null,
        Array(data?.competitonBookData?.slot?.id)
      ).map(function (y, i) {
        return {
          guestPrice: gPrice,
          memberPrice: mPrice,
          activePrice: gPrice,
          playerType: "Guest",
          playerTypeId: playerTypeId?.guestPlayerTypeId,
          id: i + 1,
        };
      });

      setState((prev) => ({ ...prev, playersPriceList: arr }));

      setState((prev) => ({ ...prev, playersPriceList: [] }));
    }
  }, [playerTypeId]);

  useEffect(() => {
    setState((prev) => ({ ...prev, isLoding: true }));
    const tournamentDetails = get("CompetitonBookData");
    if (tournamentDetails !== null) {
      const data = JSON.parse(get("CompetitonBookData"));

      let selectedDate = data?.competitonBookData?.teeTime?.time;
      var bookingsApiData = {
        fromDate: moment(selectedDate).subtract(1, "day").format("DD-MM-YYYY"),
        toDate: moment(selectedDate).add(1, "day").format("DD-MM-YYYY"),
      };
      dispatch(getListingBookings(bookingsApiData)).then((res) => {
        if (res?.payload?.data?.data?.data && res?.payload?.status === 200) {
          let listingData = res?.payload?.data?.data?.data;
          let result = [];

          result = result.concat(
            listingData?.filter((e) => {
              return (
                e?.teetime === selectedDate &&
                (e?.status === "BOOKED" || e?.status === "CREATED")
              );
            })
          );
          let totalPayers = result.reduce(
            (total, currentValue) => total + currentValue.players,
            0
          );

          if (
            result?.length &&
            (totalPayers >= 4 ||
              4 - totalPayers < data?.competitonBookData?.slot?.id)
          ) {
            setState((prev) => ({
              ...prev,
              teeTimeISBooked: true,
              isLoding: false,
              availableSlot: totalPayers,
            }));

            setState((prev) => ({
              ...prev,
              teeTimeISBooked: false,
              isLoding: false,
            }));
          }

          setState((prev) => ({
            ...prev,
            teeTimeISBooked: false,
            isLoding: false,
          }));
        }
      });

      setState((prev) => ({
        ...prev,
        isLoding: false,
      }));
    }
  }, []);

  const changeVisitorPrice = (e) => {
    let upd_obj = state.playersPriceList?.map((obj) => {
      if (obj?.id === e?.id) {
        obj.activePrice = Number(e?.guestPrice);
        obj.playerType = "Guest";
        obj.playerTypeId = playerTypeId?.guestPlayerTypeId;
      }
      return obj;
    });
    setState((prev) => ({ ...prev, playersPriceList: upd_obj }));
  };

  const changeMemberPrice = (e) => {
    let upd_obj = state.playersPriceList?.map((obj) => {
      if (obj?.id === e?.id) {
        obj.activePrice = Number(e?.memberPrice);
        obj.playerType = "Member";
        obj.playerTypeId = playerTypeId?.memberPlayerTypeId;
      }
      return obj;
    });
    setState((prev) => ({ ...prev, playersPriceList: upd_obj }));
  };

  const proceed = () => {
    if (state.teeTimeISBooked) {
      setState((prev) => ({
        ...prev,
        alertMessage: true,
        alertMessageTxt:
          "This teetimes is booked by some one please try again with another tee time !",
      }));

      if (prices[0] === null || prices[1] === null) {
        navigate("/tournament/checkout", {
          state: state.playersPriceList,
        });
        window.scrollTo(0, 0);
      }
    }
  };

  const PlayerInfoView = ({ data }) => {
    let isGuestPrice =
      state.playersPriceList[data?.id - 1]?.activePrice === data?.guestPrice &&
      state.playersPriceList[data?.id - 1]?.playerType === "Guest";
    let isMemberPrice =
      state.playersPriceList[data?.id - 1]?.activePrice === data?.memberPrice &&
      state.playersPriceList[data?.id - 1]?.playerType === "Member";

    return (
      <div className="w-auto h-auto flex flex-row md:items-center items-start gap-4 justify-between">
        <LogoView>
          <div className="w-[64px] h-[64px] rounded-full">
            <img
              src={playerImg}
              className="w-full h-full rounded-full "
              alt="img"
            />
          </div>
        </LogoView>
        <div className="w-auto md:h-[77px] h-auto flex flex-col gap-y-4 justify-between">
          <PrimaryText className="text-[20px] font-medium">
            {`${t("player.player")} ${data?.id} `}
          </PrimaryText>
          <BtnView>
            <button
              onClick={() => changeVisitorPrice(data)}
              className={`w-[183px] h-[36px] flex items-center justify-center rounded-md shadow-[0_4px_4px_0px_#00000024] ${
                isGuestPrice
                  ? "bg-primary text-white hover:cursor-default"
                  : "bg-white text-[#cccccc] hover:text-[gray] hover:bg-[#e9e9e9] border border-[#F9EDE0]"
              }`}
            >
              <span>{`${t("player.guestPrice")} €${data?.guestPrice}`}</span>
            </button>
            {data?.memberPrice ? (
              <button
                onClick={() => changeMemberPrice(data)}
                className={`w-[183px] h-[36px] flex items-center justify-center rounded-md shadow-[0_4px_4px_0px_#00000024] ${
                  isMemberPrice
                    ? "bg-primary text-white hover:cursor-default"
                    : "bg-white text-[#cccccc] hover:text-[gray] hover:bg-[#e9e9e9] border border-[#F9EDE0]"
                }`}
              >
                <span>{`${t("player.memberPrice")} €${
                  data?.memberPrice
                }`}</span>
              </button>
            ) : (
              <></>
            )}
          </BtnView>
        </div>
      </div>
    );
  };

  return (
    <>
      {state.playersPriceList?.length > 0 && (
        <Component>
          <div className="w-full h-auto">
            <PrimaryText className="text-[18px] font-bold">
              {t("player.bookingOpt")}
            </PrimaryText>
          </div>
          <div className="w-full h-auto flex flex-wrap items-center justify-between gap-y-10 mb-8 mt-2">
            {state.playersPriceList?.map((res, index) => (
              <React.Fragment key={`players${index}`}>
                <PlayerInfoView data={res} id={index + 1} />
              </React.Fragment>
            ))}
          </div>
          <div className="w-full h-auto flex flex-row gap-x-2 justify-end">
            <PrimaryText className="text-[18px] font-normal">
              <span className=" font-semibold font-[Cambay]">
                {t("player.totalPrice")}
              </span>
            </PrimaryText>
            <PrimaryText className="w-[80px] font-medium text-[19px] font-[Cambay]">
              {`€${totalPrice}`}
            </PrimaryText>
          </div>
          <button
            disabled={
              prices[0] === null || prices[1] === null || state.isLoding
            }
            onClick={() => proceed()}
            className={`w-[118px] h-[33px] flex items-center justify-center rounded-full ${
              prices[0] === null || prices[1] === null || state.isLoding
                ? "bg-primary2 hover:cursor-not-allowed"
                : "bg-primary hover:bg-primary2"
            }  md2:self-end self-start my-4 md2:mr-6 mr-0 ml-2`}
          >
            {state.isLoding ? (
              <CircularProgress
                sx={{ color: "#ffffff" }}
                size={20}
                thickness={5}
              />
            ) : (
              <span className="text-[18px] pt-[5px] font-semibold font-[Cambay]">
                {t("player.proceed")}
              </span>
            )}
          </button>
        </Component>
      )}
      {state.alertMessageTxt && (
        <TostMessage
          open={state.alertMessage}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              alertMessage: false,
              alertMessageTxt: null,
            }))
          }
          title={state.alertMessageTxt}
          type={"info"}
        />
      )}
    </>
  );
}

const Component = tw.div`
w-full
h-auto
flex
flex-col
gap-y-4
py-6
px-4
rounded-xl
bg-white255
`;
const LogoView = tw.div`
`;
const BtnView = tw.div`
w-auto 
h-auto 
flex 
flex-wrap
gap-4
`;

export default PlayerView;
