import * as types from "./types";
import { teesApi } from "@apiService";

export const getReduxTeeTime = (slug) => {
  return {
    type: types.REDUX_TEE_TIME_DATa,
    payload: slug,
  };
};

export const getCachedTeeTime = (slug) => {
  return {
    type: types.CACHED_TEE_TIME_DATa,
    payload: slug,
  };
};

export const removeReduxTeeTime = (slug) => {
  return {
    type: types.REMOVE_REDUX_TEE_TIME_DATa,
    payload: slug,
  };
};

export const getTees = (slug) => {
  return {
    type: types.CHECK_TEES,
    payload: teesApi.getTeesApi(slug),
  };
};

export const getTeeTimeDataPaginate = (slug) => {
  return {
    type: types.GET_TEETIME_DATA_PAGINATE,
    payload: teesApi.getTeesPaginate(slug),
  };
};

export const updateTime = (slug) => {
  return {
    type: types.UPDATE_TIME,
    payload: slug,
  };
};

export const updateDate = (slug) => {
  return {
    type: types.UPDATE_DATE,
    payload: slug,
  };
};

export const teeTimeApiSlug = (slug) => {
  return {
    type: types.TEE_TIME_API_SLUG,
    payload: slug,
  };
};

export const showLoder = (slug) => {
  return {
    type: types.SHOW_LODER,
    payload: slug,
  };
};
export const showCompleteLoder = (slug) => {
  return {
    type: types.SHOW_COMPLETE_LODER,
    payload: slug,
  };
};

export const networkError = (slug) => {
  return {
    type: types.NETWORK_ERROR,
    payload: slug,
  };
};

export const apiTimer = (slug) => {
  return {
    type: types.SHOW_TIMER,
    payload: slug,
  };
};

export const apiCall = (slug) => {
  return {
    type: types.API_CALL,
    payload: slug,
  };
};
