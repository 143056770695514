import React from "react";
import tw from "tailwind-styled-components";
import { SecondaryText } from "@components";
import "../componentStyle.css";

function RoundHole(props) {
  const { btnClass, onClick, active } = props;
  return (
    <Container onClick={onClick} className={btnClass}>
      <SecondaryText
        className={`text-[19px] font-bold`}
        style={{ color: active ? "rgb(0,0,0)" : "rgb(255,255,255)" }}
      >
        {props.children}
      </SecondaryText>
    </Container>
  );
}

const Container = tw.div`
w-[40px]
h-[40px]
pt-[5px]
border-black03
rounded-full
flex
justify-center
items-center
webkitHighlight
`;

export default RoundHole;
