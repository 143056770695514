import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { PrimaryText } from "@components";
import Timer from "./widgets/timer";
import CheckOutForm from "./widgets/checkOutForm";

function CompetitionCheckOut() {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const back = () => {
    navigate(-1);
  };

  return (
    <>
      <Timer />
      <BackBtn onClick={() => back()}>
        <ArrowBackIosNewIcon fontSize="small" sx={{ color: "white" }} />
        <div className="ml-[5px]">
          <PrimaryText className="text-white255 font-bold">
            {t("checkOut.back")}
          </PrimaryText>
        </div>
      </BackBtn>
      <HomeScreenContent>
        <CheckOutForm />
      </HomeScreenContent>
    </>
  );
}

const BackBtn = tw.button`
w-auto
h-auto 
flex 
xl1:pl-9  pl-4 
items-center
`;
const HomeScreenContent = tw.div`
w-full
h-auto 
flex
md:pt-[10px]
pt-[20px]
mb-[90px]
`;

export default CompetitionCheckOut;
