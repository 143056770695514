import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { get } from "@utils";
import { TournamentDetailsCard } from "@components";

function CardDetails() {
  const [state, setState] = useState({
    tournamentDetails: null,
  });

  useEffect(() => {
    const tournamentDetails = get("CompetitonBookData");
    if (tournamentDetails !== null) {
      const data = JSON.parse(get("CompetitonBookData"));
      setState((prev) => ({
        ...prev,
        tournamentDetails: data?.competitonBookData,
      }));
    } else {
      setState((prev) => ({ ...prev, tournamentDetails: null }));
    }
  }, []);

  return (
    <Root>
      <TournamentDetailsCard data={state.tournamentDetails?.data} />
    </Root>
  );
}

const Root = tw.div`
w-full 
h-auto 
`;

export default CardDetails;
