import * as types from "./types";
import { teeTimebooking } from "@apiService";

export const getSelectedPlayerData = (slug) => {
  return {
    type: types.GET_SELECTED_PLAYER,
    payload: slug,
  };
};

export const getBookingData = (slug) => {
  return {
    type: types.GET_BOOKING_DATA,
    payload: teeTimebooking.getTeeTimeBooking(slug),
  };
};

export const cancelBooking = (slug) => {
  return {
    type: types.CANCEL_BOOKING,
    payload: teeTimebooking.cancelBooking(slug),
  };
};

export const getListingBookings = (slug) => {
  return {
    type: types.GET_LISTING_BOOKING_DATA,
    payload: teeTimebooking.getListingBooking(slug),
  };
};
