import React from "react";
import { useSelector } from "react-redux";
import { TournamentDetailsCard } from "@components";

function CardDetails() {
  const singleTournaments = useSelector(
    (state) => state.competition?.singleTournaments
  );
  const tournamentData = singleTournaments?.tournament;

  return (
    <div className={scrollDiv}>
      <TournamentDetailsCard data={tournamentData} />
    </div>
  );
}

const scrollDiv = `
w-full 
h-auto 
rounded-lg 
drop-shadow-lg 
`;

export default CardDetails;
