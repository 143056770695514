import * as types from "./types";

const initialState = {
  tournamentPaymentIntentData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_PAYMENT_INTENT: {
      return {
        ...state,
        tournamentPaymentIntentData: action?.payload?.data,
      };
    }
    default:
      return state;
  }
};

export default reducer;
