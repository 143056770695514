import React from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import { PrimaryText } from "@components";

function Cookies() {
  const { t } = useTranslation("common");

  return (
    <Root>
      <div className="w-full h-full ">
        <PrimaryText className="text-3xl text-white255 font-bold font-[Cambay] text-center">
          {t("footer.cookies")}
        </PrimaryText>
      </div>
    </Root>
  );
}

const Root = tw.div`
px-4
py-8
w-full
h-[650px]
`;

export default Cookies;
