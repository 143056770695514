import React, { useReducer } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  PrimaryText,
  CustomButton,
  CustomModal,
  TostMessage,
} from "@components";
import { IsValidatePassword } from "@utils";
import { userLogOut } from "@store";
import { resetPassword } from "../../store/users/action";

function ResetPassword() {
  const { i18n, t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      password: "",
      passwordError: false,
      passwordType: true,
      confirmPassword: "",
      confirmPasswordError: false,
      validatePasswordError: false,
      confirmPasswordType: true,
      btnLoading: false,
      successModal: false,
      alertMessage: false,
      alertMessageTxt: null,
    }
  );

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#535754ad",
      },
    },
  });

  const closeModal = () => {
    dispatch(userLogOut());
    i18n.changeLanguage("en");
    setState({ successModal: false });
    navigate("/");
  };

  const cancel = () => {
    navigate("/");
  };

  const submit = () => {
    if (state.password === "") {
      setState({
        passwordError: true,
        confirmPasswordError: false,
        validatePasswordError: false,
      });
    } else if (!IsValidatePassword(state.password)) {
      setState({
        validatePasswordError: true,
        alertMessage: true,
        alertMessageTxt:
          "Password must be at least 8 characters long, contain one uppercase letter, one number, and one special character !",
      });
    } else if (state.password !== state.confirmPassword) {
      setState({
        passwordError: false,
        confirmPasswordError: true,
        validatePasswordError: false,
      });
    } else {
      setState({
        passwordError: false,
        confirmPasswordError: false,
        validatePasswordError: false,
        btnLoading: true,
      });
      dispatch(
        resetPassword({
          password: state.password,
          passwordConfirmation: state.confirmPassword,
        })
      ).then((res) => {
        setState({ btnLoading: false });
        if (res?.payload?.data && res?.payload?.status === 200) {
          setState({ successModal: true });
        } else {
          res?.payload?.data?.raw?.message
            ? setState({
                alertMessage: true,
                alertMessageTxt: res?.payload?.data?.raw?.message,
              })
            : res?.payload?.response?.data?.error?.message
            ? setState({
                alertMessage: true,
                alertMessageTxt: res?.payload?.response?.data?.error?.message,
              })
            : res?.payload?.response?.data?.message
            ? setState({
                alertMessage: true,
                alertMessageTxt: res?.payload?.response?.data?.message,
              })
            : setState({
                alertMessage: true,
                alertMessageTxt:
                  "A generic error occurred on the Change Password !",
              });
        }
      });
    }
  };

  const CustomInput = (props) => {
    return (
      <div className="w-full h-auto flex flex-col items-start justify-start mb-2">
        <span className="text-[16px] text-textColor font-[Cambay]">
          {props.title}
        </span>
        <div className="w-full h-auto flex flex-row relative">
          <input
            type={props.showPassword ? "password" : "text"}
            placeholder={props.title}
            className={textInput}
            value={props.value}
            onChange={props.onChange}
            maxLength={props.maxLength}
          />
          <div className="w-[30px] h-[30px] absolute inset-y-0 right-0 top-[8px] flex items-center pr-2 ">
            <ThemeProvider theme={theme}>
              <button onClick={() => props.changeType()}>
                {" "}
                {!props.showPassword ? (
                  <VisibilityOutlinedIcon fontSize="medium" color="secondary" />
                ) : (
                  <VisibilityOffOutlinedIcon
                    fontSize="medium"
                    color="secondary"
                  />
                )}
              </button>
            </ThemeProvider>
          </div>
        </div>
        <p className="text-red-500 text-[13px] italic">
          {props.error
            ? props.errorMessage
              ? props.errorMessage
              : "Please fill out this field."
            : props.passwordError
            ? "Password and Confirm Password does not match"
            : ""}
        </p>
      </div>
    );
  };

  return (
    <Root>
      <BackBtn>
        <ArrowBackIosNewIcon fontSize="small" sx={{ color: "white" }} />
        <div onClick={() => navigate(-1)} className="ml-[5px]">
          <PrimaryText className="text-textColor text-[16px]">
            {t("checkOut.back")}
          </PrimaryText>
        </div>
      </BackBtn>
      <PrimaryText className="text-textColor font-semibold text-[20px]">
        {"RESET PASSWORD"}
      </PrimaryText>
      <div className="w-full h-auto bg-boxBackground rounded-lg sm2:p-4 p-2 my-6">
        <div className="w-full h-auto">
          <PrimaryText className="text-textColor text-[18px] ">
            {"Reset Password"}
          </PrimaryText>
        </div>

        <div className="w-full h-auto flex md2:flex-row flex-col items-start justify-between gap-4 mt-4 lg:px-8 md:px-4 sm:px-2 px-[4px]">
          {CustomInput({
            title: "Password",
            value: state.password,
            onChange: (event) => {
              setState({
                password: event.target.value,
                passwordError: false,
                confirmPasswordError: false,
                validatePasswordError: false,
              });
            },
            showPassword: state.passwordType,
            changeType: () => {
              setState({ passwordType: !state.passwordType });
            },
            error: state.passwordError || state.validatePasswordError,
            errorMessage: state.validatePasswordError
              ? "Password must be at least 8 characters long, contain one uppercase letter, one number, and one special character"
              : "Please Enter Password",
            maxLength: 25,
          })}
          {CustomInput({
            title: "Confirm Password",
            value: state.confirmPassword,
            onChange: (event) => {
              setState({
                confirmPassword: event.target.value,
                passwordError: false,
                confirmPasswordError: false,
                validatePasswordError: false,
              });
            },
            showPassword: state.confirmPasswordType,
            changeType: () => {
              setState({ confirmPasswordType: !state.confirmPasswordType });
            },
            passwordError: state.confirmPasswordError,
            maxLength: 25,
          })}
        </div>
        <div className="w-full h-auto flex flex-row items-center justify-start gpa-4 mt-6 mb-4 md:ml-6 ml-0">
          <Cancle onClick={() => cancel()}>
            <PrimaryText className={"text-white255 text-md"}>
              {"CANCEL"}
            </PrimaryText>
          </Cancle>
          <div className="md:w-[20%] sm:w-[40%] w-[47%] sm:ml-4 ml-2">
            <CustomButton
              onClick={() => submit()}
              userLoading={state.btnLoading}
              value={"SAVE"}
              color="primary"
            />
          </div>
        </div>
      </div>
      <CustomModal
        openModal={state.successModal}
        closeModal={closeModal}
        title={"Password changed successfully!"}
        description={
          "Your Password has been changed Successfully Please login to your account again"
        }
      />
      {state.alertMessageTxt && (
        <TostMessage
          open={state.alertMessage}
          onClose={() =>
            setState({ alertMessage: false, alertMessageTxt: null })
          }
          title={state.alertMessageTxt}
          type={"info"}
        />
      )}
    </Root>
  );
}

const Root = tw.div`
w-full
h-full
xl3:px-60
xl2:px-40
xl1:px-36
lg2:px-30
lg:px-20
md3:px-16
md2:px-10
md:px-6
sm:px-4
px-2
py-8
`;

const BackBtn = tw.div`
w-full
flex
flex-row
items-center
h-auto
mb-8
`;

const textInput = `
w-full 
bg-gray-200
text-textColor2 
border-[2px] 
rounded-lg
py-[12px]
mb-[2px]
px-4 
leading-tight 
focus:outline-none 
border-gray-200
shadow-[0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)]
focus:border-[black]
placeholder:text-[#757680]
`;

const Cancle = tw.button`
md:w-[20%] sm:w-[40%] w-[47%]
h-[40px]
rounded-[23px]
bg-gradient-to-r from-red-400 to-red255
hover:from-red255 hover:to-red255
flex
justify-center
items-center
webkitHighlight
`;

export default ResetPassword;
