import * as types from "./types";

export const clubCoursesData = (slug) => {
  return {
    type: types.CLUB_COURSES_DATA,
    payload: slug,
  };
};

export const activeCourse = (slug) => {
  return {
    type: types.ACTIVE_COURSES,
    payload: slug,
  };
};

export const activePlayer = (slug) => {
  return {
    type: types.ACTIVE_PLAYER,
    payload: slug,
  };
};
