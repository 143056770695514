import * as types from "./types";
import { paymentApi } from "@apiService";

export const createCustomer = (slug) => {
  return {
    type: types.CREATE_CUSTOMER,
    payload: paymentApi.createCustomer(slug),
  };
};

export const createPayment = (slug) => {
  return {
    type: types.CREATE_PAYMENT,
    payload: paymentApi.createPayment(slug),
  };
};

export const createStripeCustomer = (slug) => {
  return {
    type: types.CREATE_STRIPE_CUSTOMER,
    payload: paymentApi.createStripeCustomer(slug),
  };
};

export const createPaymentIntent = (slug) => {
  return {
    type: types.CREATE_PAYMENT_INTENT,
    payload: paymentApi.createPaymentIntent(slug),
  };
};
export const paymentUpdateBooking = (slug) => {
  return {
    type: types.PAYMENT_UPDATE_BOOKING,
    payload: paymentApi.paymentUpdateBookingApi(slug),
  };
};

export const updatePaymentStatus = (id, slug) => {
  return {
    type: types.UPDATE_PAYMENT_STATUS,
    payload: paymentApi.updatePaymentStatus(id, slug),
  };
};

export const userCard = (slug) => {
  return {
    type: types.USER_CARD,
    payload: paymentApi.userCard(slug),
  };
};

export const getPaymentDetail = (slug) => {
  return {
    type: types.PAYMENT_DETAIL,
    payload: paymentApi.getPaymentDetails(slug),
  };
};

export const payLoader = (slug) => {
  return {
    type: types.PAY_LOADER,
    payload: slug,
  };
};
