import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Zoom } from "@mui/material";
import { PrimaryText } from "@components";
import profileLogo from "@assets/image/profile.jpeg";
import "../../containerStyle.css";

function AccountMenu({ data, log_OUT, goToPage }) {
  const { t } = useTranslation("common");
  let imageUrl = data?.user?.image?.url ? data?.user?.image?.url : "";

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      menuModal: null,
    }
  );

  const open = Boolean(state.menuModal);
  const handleClick = (event) => {
    setState({ menuModal: event.currentTarget });
  };
  const handleClose = () => {
    setState({ menuModal: null });
  };

  const profile = () => {
    handleClose();
    goToPage("/profile");
  };

  const setting = () => {
    handleClose();
    goToPage("/setting");
  };

  const logOut = () => {
    handleClose();
    log_OUT();
  };

  return (
    <React.Fragment>
      <Btn onClick={handleClick}>
        <IconButton size="medium" disableRipple={false}>
          <Avatar
            sx={{
              width: 50,
              height: 50,
              borderWidth: 2,
              borderColor: "rgb(0,99,168,1)",
            }}
            alt="img"
            src={imageUrl ? imageUrl : profileLogo}
          />
        </IconButton>
        <div className="max-w-[130px] w-auto truncate pr-1 text-textColor">
          <PrimaryText className="font-semibold text-textColor uppercase">
            {data?.user?.firstName}
          </PrimaryText>
        </div>
        <div
          className={`w-fit h-fit ${
            state.menuModal
              ? "duration-500 transform rotate-180"
              : "duration-500 transform rotate-0"
          }`}
        >
          <ExpandMoreIcon fontSize="large" sx={{ color: "white" }} />
        </div>
      </Btn>

      <Menu
        anchorEl={state.menuModal}
        id="account-menu"
        open={open}
        onClose={handleClose}
        TransitionComponent={Zoom}
        PaperProps={{
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 4px rgba(0,0,0,0.32))",
            borderRadius: "12px",
            mt: 1,
            padding: "4px",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="w-auto h-auto flex flex-col px-2 items-center justify-center">
          <MenuBtn onClick={profile}>
            <PrimaryText className={btnTxt}>{t("header.profile")}</PrimaryText>
          </MenuBtn>
          <Line />
          <MenuBtn onClick={setting}>
            <PrimaryText className={btnTxt}>{t("header.setting")}</PrimaryText>
          </MenuBtn>
          <Line />
          <MenuBtn onClick={logOut}>
            <PrimaryText className={btnTxt}>{t("header.logout")}</PrimaryText>
          </MenuBtn>
        </div>
      </Menu>
    </React.Fragment>
  );
}

const Btn = tw.div`
flex
flex-row
items-center
justify-between
text-center
cursor-pointer
`;
const Line = tw.div`
w-full
h-[1.1px] 
bg-[#C5C5C5] 
`;
const MenuBtn = tw.div`
w-full 
h-auto 
py-2 
px-4 
hover:cursor-pointer 
`;
const btnTxt = `
font-[Cambay] 
text-md 
text-textColor2 
uppercase
hover:text-primary
`;

export default AccountMenu;
