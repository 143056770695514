import * as types from "./types";
import { headerApi } from "@apiService";

export const getHeaderData = (slug) => {
  return {
    type: types.GET_HEADER_DATA,
    payload: headerApi.getHeader(slug),
  };
};

export const isModal = (slug) => {
  return {
    type: types.CHECK_MODAL,
    payload: slug,
  };
};
