import React from "react";
import tw from "tailwind-styled-components";
import { ClubDetails } from "@containers";
import EnterCard from "./widgets/enterCard";
import BookCompetition from "./widgets/bookCompetition";
import CardDetails from "./widgets/cardDetails";

function Tournament() {
  return (
    <Root>
      <div className={mainView}>
        <div className="xl2:w-[80%] w-full h-auto flex lg2:flex-row flex-col lg2:items-start items-center justify-between gap-6">
          <div className={cardsView}>
            <EnterCard />
          </div>

          <div className={bookingView}>
            <CardDetails />
            <BookCompetition />
          </div>
        </div>

        <div className={clubView}>
          <div className="w-full h-full flex xl:justify-end justify-center rounded-lg">
            <ClubDetails />
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = tw.div`
w-full 
h-auto
px-4
pt-4
flex
items-between
justify-center
`;
const mainView = `
h-auto
min-h-[470px]
w-full
flex
xl2:flex-row
flex-col
xl2:items-start
items-center
justify-between
gap-6
`;
const cardsView = `
lg2:w-[32%]
w-full
h-auto 
flex
flex-col 
items-center
mb-6
`;
const bookingView = `
lg2:w-[68%]
w-full 
h-auto 
md2:my-0 
my:-[40px]

flex 
flex-col
gap-y-4
items-center 
justify-start
mb-6
`;
const clubView = `
xl2:w-[20%]
w-full
max-w-[360px]
h-auto
md:flex
flex-row
items-between
justify-between
rounded-lg
`;

export default Tournament;
