import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import { PrimaryText } from "@components";

function Setting() {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  return (
    <Root>
      <Line />
      <div className="h-[500px] w-full flex flex-col items-center">
        <PrimaryText className="text-2xl text-textColor font-bold font-[Cambay] text-center p-4">
          {t("header.setting")}
        </PrimaryText>
        <div className="h-auto w-[350px] bg-boxBackground rounded-xl flex flex-col items-center pb-6 font-[Cambay] ">
          <OptionButton onClick={() => navigate("/setting/changeLanguage")}>
            <span className="font-[Cambay] font-medium">
              {" "}
              {t("setting.changeLanguage")}{" "}
            </span>
          </OptionButton>
          <OptionButton onClick={() => navigate("/setting/change-password")}>
            <span className="font-[Cambay] font-medium">
              {" "}
              {t("setting.changePassword")}{" "}
            </span>
          </OptionButton>
        </div>
      </div>
    </Root>
  );
}

const Root = tw.div`
px-4
py-8
h-full
w-full
`;
const Line = tw.div`
w-full
h-[1.1px] 
bg-primary
my-2
`;
const OptionButton = tw.button`
rounded-full
mt-6
pb-[7px]
pt-[10px]
text-center
justify-center
shadow-md
webkitHighlight
bg-gray-300
hover:bg-gray-200
w-[80%]
font-medium
`;

export default Setting;
