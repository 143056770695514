import React from "react";
import { ClubDetails } from "@containers";
import tw from "tailwind-styled-components";
import CompentitionView from "./widgets/competitionView";

function OpenCompetitions() {
  return (
    <Root>
      <div className={mainView}>
        <CompentitionView />
      </div>
      <div className={clubView}>
        <div className="w-full h-full flex">
          <ClubDetails />
        </div>
      </div>
    </Root>
  );
}

const Root = tw.div`
md:px-8 sm:px-4 px-2
mt-4
h-auto
min-h-[600px]
w-full
flex
lg2:flex-row
flex-col
lg2:items-start
items-center
justify-center
gap-x-10
`;
const mainView = `
lg2:w-[70%]
w-full 
h-full 
flex
justify-center
items-center
mb-5
lg2:mr-3
mr-0
`;
const clubView = `
lg2:w-[30%]
w-full
h-auto
max-w-[360px]
flex
flex-row
items-center
justify-center
rounded-lg
`;

export default OpenCompetitions;
