import React from "react";
import moment from "moment";
import _ from "lodash";

export function filterTeeTimeData(props) {
  if (props) {
    const unique = props?.filter((obj, index) => {
      return (
        index ===
        props?.findIndex(
          (o) => obj?.course === o?.course && o?.time === obj?.time
        )
      );
    });

    let groupWithCourse = unique.reduce(function (r, a) {
      r[a?.course] = r[a?.course] || [];
      r[a?.course].push(a);
      return r;
    }, Object.create(null));

    const sortWithCourseName = Object.keys(groupWithCourse)
      .sort()
      .reduce((obj, key) => {
        obj[key] = groupWithCourse[key];
        return obj;
      }, {});

    let allCourse = Object.values(sortWithCourseName);

    let timeFilter = [];
    allCourse?.map((item, index) => {
      let filterForMorning = _.filter(item, function (e) {
        return moment(e?.time).format("H") < 12;
      });
      let filterForEvening = _.filter(item, function (e) {
        return moment(e?.time).format("H") > 11;
      });
      let timeData = [
        {
          type: "Morning",
          courseName: filterForMorning.length ? filterForMorning[0].course : "",
          data: filterForMorning,
        },
        {
          type: "Afternoon",
          courseName: filterForEvening.length ? filterForEvening[0].course : "",
          data: filterForEvening,
        },
      ];
      timeFilter.push(timeData);
    });
    return timeFilter;
  } else {
    return {};
  }
}
