import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import tw from "tailwind-styled-components";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import { Zoom } from "@mui/material";
import { get } from "@utils";
import { PrimaryText } from "@components";
import { getPrivacyPolicy } from "@store";
import "../../containerStyle.css";

function PrivacyPolicyModal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const privacyData = useSelector((state) => state.footers.privacyPolicuData);

  useEffect(() => {
    var dateLanguage = "en";
    const selectLanguage = get("Language");
    if (selectLanguage !== null) {
      dateLanguage =
        selectLanguage === "ge"
          ? "de-DE"
          : selectLanguage === "fr"
          ? "fr-FR"
          : selectLanguage;
    } else {
      dateLanguage = "en";
    }
    dispatch(getPrivacyPolicy(`privacy-policy?locale=${dateLanguage}`));
  }, []);

  return (
    <Dialog
      open={props.openModal}
      TransitionComponent={Zoom}
      onClose={props.closeModal}
    >
      <Root>
        <div className="w-full h-auto flex flex-row justify-between items-start">
          <PrimaryText className={titleTxt}>
            {t("footer.PrivacyPolicy")}
          </PrimaryText>
          <CloseBtn>
            <IconButton onClick={props.closeModal}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </CloseBtn>
        </div>
        <Content>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            children={privacyData.toString()}
          />
        </Content>
      </Root>
    </Dialog>
  );
}

const Root = tw.div`
w-full 
h-full 
whitespace-pre-line 
flex 
flex-col 
md:pl-8 sm:pl-2 pl-[10px]
md:pr-8 sm:pr-2 pr-[6px]
sm:py-2  py-[5px] 
custom-scroll 
overflow-auto
`;
const titleTxt = `
md:text-3xl sm:text-2xl sm2:text-xl text-lg 
text-textColor2
font-bold 
font-[Cambay]
 mb-8 
 underline 
 underline-offset-8
`;
const CloseBtn = tw.div`
w-[50px] 
h-[50px] 
md:mr-[-38px] mr-0 
mt-[-7px] 
flex 
items-start 
justify-end
`;
const Content = tw.div`
break-words 
hyphens-auto
`;

export default PrivacyPolicyModal;
