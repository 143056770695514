const PrimaryText = (props) => {
  return (
    <span
      style={{ ...PrimaryTextStyle, ...props.style }}
      className={`${props.className} font-cambay`}
    >
      {props.children}
    </span>
  );
};

const SecondaryText = (props) => {
  return (
    <span
      style={{ ...SecondaryTextStyle, ...props.style }}
      className={`${props.className} text-textColor font-cambay font-medium`}
    >
      {props.children}
    </span>
  );
};

const HeadingText = (props) => {
  return (
    <div
      className={`${props.divClass} bg-black02 rounded-2xl w-[100px] h-auto pt-[6px] pb-[1px] flex items-center justify-center`}
    >
      <span
        style={{ ...HeadingTextStyle, ...props.style }}
        className={`${props.txtClass} text-sm  font-cambay text-textColor`}
      >
        {props.children}
      </span>
    </div>
  );
};

const DarkText = (props) => {
  return (
    <span
      className={`${props.className} text-white255 text-sm leading-tight font-bold  font-[Cambay]`}
    >
      {props.children}
    </span>
  );
};

const PlanText = (props) => {
  return (
    <span
      className={`${props.className} text-textColor text-sm  font-[Cambay]`}
    >
      {props.children}
    </span>
  );
};

const PrimaryTextStyle = {};

const SecondaryTextStyle = {
  fontSize: "18px",
  fontStyle: "normal",
};

const HeadingTextStyle = {};

export { PrimaryText, SecondaryText, HeadingText, DarkText, PlanText };
