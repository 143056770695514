import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "../../containerStyle.css";

function ButtonField(props) {
  const {
    setOpen,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <IconButton
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label="delete"
      onClick={() => setOpen?.((prev) => !prev)}
    >
      <CalendarMonthOutlinedIcon sx={{ color: "white" }} />
    </IconButton>
  );
}

ButtonField.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputProps: PropTypes.shape({
    "aria-label": PropTypes.string,
  }),
  InputProps: PropTypes.shape({
    endAdornment: PropTypes.node,
    startAdornment: PropTypes.node,
  }),
  label: PropTypes.node,
  setOpen: PropTypes.func,
};

function ButtonDatePicker(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      defaultValue={moment()}
      disablePast
    />
  );
}

ButtonDatePicker.propTypes = {
  /**
   * Overridable component slots.
   * @default {}
   */
  slots: PropTypes.any,
};

function DatePickers(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={1}>
        <ButtonDatePicker
          disabled={props?.disabled}
          value={props?.value}
          onChange={(newValue) => props?.newDate(newValue)}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export default DatePickers;
