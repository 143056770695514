import React, { useEffect, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import moment from "moment";
import tw from "tailwind-styled-components";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { PrimaryText, TostMessage } from "@components";
import { remove } from "@utils";
import {
  isModal,
  payLoader,
  getBookingData,
  createPaymentIntent,
  createStripeCustomer,
  paymentUpdateBooking,
} from "@store";
import SuccessModal from "./successModal";
import "../../containerStyle.css";

function PaymentDetails(props) {
  const { onPayClick, inputComplete, formData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const playersPriceList = location.state;
  const clubData = useSelector((state) => state.clubs.clubsData);
  const facilityData = useSelector((state) => state.courses?.clubCoursData);
  const facilitiesData = clubData?.data?.[0]?.attributes?.facility?.data;
  const bookingSlot = formData?.tournamentData?.slot;
  const bookingTeeTime = formData?.tournamentData?.teeTime;

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      payDetail: null,
      payError: false,
      successModal: false,
      alertMessage: false,
      alertMessageTxt: null,
      bookingListPayload: [],
      paymentCancellation: null,
      paymentCancellationMessage: "",
    }
  );

  const prices = playersPriceList?.length
    ? playersPriceList?.map((a) => a?.activePrice)
    : "";
  const totalPrice = playersPriceList?.length
    ? prices?.reduce((acc, curr) => acc + curr)
    : "";

  useEffect(() => {
    if (inputComplete) {
      if (state.payDetail === null || !state.payDetail?.complete) {
        setState({ payError: true });
      } else {
        setState({ payError: false });
        handleSubmit();
      }
    }
  }, [inputComplete]);

  useEffect(() => {
    if (state.successModal) {
      dispatch(isModal(true));
    } else {
      dispatch(isModal(false));
    }
  }, [state.successModal]);

  useEffect(() => {
    let player1Detail = {
      teeId: bookingTeeTime?.id,
      teeTime: moment(bookingTeeTime?.time).format("YYYY-MM-DDTHH:mm"),
      holes: bookingTeeTime?.holes,
      amount: playersPriceList?.[0]?.activePrice,
      course: formData?.tournamentData?.data?.courseId?.courseName,
      players: 1,
    };
    let player2Detail = {
      teeId: bookingTeeTime?.id,
      teeTime: moment(bookingTeeTime?.time).format("YYYY-MM-DDTHH:mm"),
      holes: bookingTeeTime?.holes,
      amount: playersPriceList[1]?.activePrice,
      course: formData?.tournamentData?.data?.courseId?.courseName,
      players: 1,
    };
    let player3Detail = {
      teeId: bookingTeeTime?.id,
      teeTime: moment(bookingTeeTime?.time).format("YYYY-MM-DDTHH:mm"),
      holes: bookingTeeTime?.holes,
      amount: playersPriceList[2]?.activePrice,
      course: formData?.tournamentData?.data?.courseId?.courseName,
      players: 1,
    };
    let player4Detail = {
      teeId: bookingTeeTime?.id,
      teeTime: moment(bookingTeeTime?.time).format("YYYY-MM-DDTHH:mm"),
      holes: bookingTeeTime?.holes,
      amount: playersPriceList[3]?.activePrice,
      course: formData?.tournamentData?.data?.courseId?.courseName,
      players: 1,
    };
    let playerArr = [];

    if (bookingSlot?.id === 1) {
      playerArr = [player1Detail];
    } else if (bookingSlot?.id === 2) {
      playerArr = [player1Detail, player2Detail];
    } else if (bookingSlot?.id === 3) {
      playerArr = [player1Detail, player2Detail, player3Detail];
    } else {
      playerArr = [player1Detail, player2Detail, player3Detail, player4Detail];
    }

    setState({ bookingListPayload: playerArr });
  }, [playersPriceList, bookingSlot]);

  const closeSuccessModal = () => {
    setState({
      successModal: false,
      paymentCancellation: null,
      paymentCancellationMessage: "",
    });
    navigate("/tournament");
    remove("localUserData");
    window.scrollTo(0, 0);
  };

  const change = (e) => {
    setState({ payError: false, payDetail: e });
    onPayClick();
  };

  const handleSubmit = async () => {
    onPayClick();
    dispatch(payLoader(true));

    if (!stripe || !elements) {
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      number: elements.getElement(CardNumberElement),
    });
    if (error) {
      setState({
        alertMessage: true,
        alertMessageTxt: error?.message,
      });
      dispatch(payLoader(false));
    }
    if (paymentMethod) {
      const cardElement = elements.getElement(CardElement, CardNumberElement);
      const number = elements.getElement(CardNumberElement);
      let token = await stripe.createToken(cardElement, number);
      const totalAmount = formData.bookingListPayload.reduce((acc, booking) => {
        return acc + booking.netRate;
      }, 0);
      const stripeCustomerData = {
        name: formData?.firstName + " " + formData?.lastName,
        email: formData?.email,
        tokenId: token?.token?.id,
      };

      await dispatch(createStripeCustomer(stripeCustomerData)).then(
        async (res) => {
          if (res?.payload?.data?.id) {
            let paymentApiData = {
              name: formData?.firstName + " " + formData?.lastName,
              email: formData?.email,
              contactPhone: formData?.telePhone,
              customer: res?.payload?.data?.id,
              bookingPayment: state?.bookingListPayload,
              facilityId: facilityData?.id,
              country: formData?.country?.label,
              handicap: formData?.handicap,
              cdhNumber: formData?.cdhNumber,
              currency: "EUR",
              description: formData?.specialRequests
                ? formData?.specialRequests
                : "Booking open-competition Tee-Time",
              amount: totalAmount,
              paymentMethod: paymentMethod?.id,
            };
            createPayment(paymentApiData);
          } else {
            dispatch(payLoader(false));
            res?.payload?.data?.raw?.message
              ? setState({
                  alertMessage: true,
                  alertMessageTxt: res?.payload?.data?.raw?.message,
                })
              : res?.payload?.response?.data?.error?.message
              ? setState({
                  alertMessage: true,
                  alertMessageTxt: res?.payload?.response?.data?.error?.message,
                })
              : res?.payload?.response?.data?.message
              ? setState({
                  alertMessage: true,
                  alertMessageTxt: res?.payload?.response?.data?.message,
                })
              : setState({
                  alertMessage: true,
                  alertMessageTxt:
                    "A generic error occurred on the Card Details !",
                });
          }
        }
      );
    }
  };

  const createPayment = async (apiData) => {
    await dispatch(createPaymentIntent(apiData)).then(async (res) => {
      let paymentResSuccess = res?.payload?.data?.data?.paymentsDetail;
      let paymentStatus = res?.payload?.data?.data?.payment?.status;
      if (paymentStatus === "pending") {
        if (paymentResSuccess?.status === "requires_action") {
          let securePayment = await stripe.confirmCardPayment(
            paymentResSuccess?.clientSecret,
            {
              setup_future_usage: "off_session",
            }
          );
          if (
            securePayment?.paymentIntent &&
            securePayment?.paymentIntent?.status === "requires_capture"
          ) {
            tournamentBooking(paymentResSuccess);
            dispatch(payLoader(true));
          } else if (securePayment?.error) {
            let data = {
              paymentId: paymentResSuccess?.paymentId,
              paymentIntentId: paymentResSuccess?.paymentIntentId,
              status: "failed",
              paymentCancelReason: securePayment?.error?.message,
            };
            dispatch(paymentUpdateBooking(data));
            dispatch(payLoader(false));
            setState({
              alertMessage: true,
              alertMessageTxt: `${securePayment?.error?.message} Please try again with another card`,
            });
          }
        } else if (paymentResSuccess?.status === "requires_capture") {
          tournamentBooking(paymentResSuccess);
          dispatch(payLoader(true));
        }
      } else if (
        res?.payload?.response?.data?.data?.payment?.message &&
        res?.payload?.response?.data?.data?.payment?.status === "failed"
      ) {
        setState({
          alertMessage: true,
          alertMessageTxt: `${res?.payload?.response?.data?.data?.payment?.message} Please try again with another card`,
        });
        dispatch(payLoader(false));
      } else {
        dispatch(payLoader(false));
        res?.payload?.response?.data?.message
          ? setState({
              alertMessage: true,
              alertMessageTxt: res?.payload?.response?.data?.message,
            })
          : res?.payload?.response?.data?.error?.message
          ? setState({
              alertMessage: true,
              alertMessageTxt: res?.payload?.response?.data?.error?.message,
            })
          : setState({
              alertMessage: true,
              alertMessageTxt:
                "A generic error occurred on the Payment Intent !",
            });
      }
    });
  };

  const tournamentBooking = async (paymentResSuccess) => {
    const bookingApiData = {
      bookings: formData?.bookingListPayload,
      facilityId: facilitiesData?.id ? facilitiesData?.id : 55,
      contactPhone: formData?.telePhone,
      contactEmail: formData?.email,
      amountPaid: totalPrice,
      clubPhone: clubData?.data ? clubData?.data?.[0]?.attributes?.phone : "",
      contactFirstName: formData?.firstName,
      contactLastName: formData?.lastName,
    };
    await dispatch(getBookingData(bookingApiData)).then((res) => {
      if (
        res?.payload?.data &&
        res?.payload?.status === 200 &&
        res?.payload?.data?.data?.groupId
      ) {
        let bookingRes = res?.payload?.data?.data;
        const newFormattedBookingData = bookingRes?.bookingIds?.map(
          ({ teetime, courseId, ...rest }) => ({
            ...rest,
            teeTime: moment(teetime).format("YYYY-MM-DDTHH:mm"),
          })
        );
        let data = {
          paymentId: paymentResSuccess?.paymentId,
          paymentIntentId: paymentResSuccess?.paymentIntentId,
          paymentLogs: newFormattedBookingData,
          bulkBookingId: bookingRes?.groupId,
        };
        dispatch(paymentUpdateBooking(data));
        dispatch(payLoader(false));
        setState({ successModal: true });
      } else {
        let data = {
          paymentId: paymentResSuccess?.paymentId,
          paymentIntentId: paymentResSuccess?.paymentIntentId,
          status: "failed",
          paymentCancelReason: "Payment got canceled due to booking failed",
        };
        dispatch(paymentUpdateBooking(data));
        dispatch(payLoader(false));
        if (res?.payload?.data?.raw?.message) {
          setState({
            alertMessage: true,
            alertMessageTxt: res?.payload?.data?.raw?.message,
          });
        } else if (
          res?.payload?.response?.status === 400 &&
          res?.payload?.response?.data?.error?.message
        ) {
          dispatch(payLoader(false));
          remove("localUserData");
          setState({
            alertMessage: true,
            alertMessageTxt:
              "This tee time is already booked by some one please try again with different tee time !",
          });
          setTimeout(() => {
            navigate("/tournament");
            window.scrollTo(0, 0);
          }, 7000);
        } else if (res?.payload?.response?.data?.message) {
          setState({
            alertMessage: true,
            alertMessageTxt: res?.payload?.response?.data?.message,
          });
        } else {
          setState({
            alertMessage: true,
            alertMessageTxt: "A generic error occurred on the Booking time !",
          });
        }
      }
    });
  };

  return (
    <>
      <div className="mt-8 mb-4">
        <PrimaryText className="text-white255 text-xl font-medium">
          {t("tournamentCheckout.paymentMethod")}
        </PrimaryText>
      </div>
      <Component>
        <div className="w-full h-auto">
          <CardElement
            onChange={(e) => change(e)}
            className={`${paymentImput}`}
          />

          <Collapse in={state.payError}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setState({ payError: false });
                    onPayClick();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              missing card details for payment — <strong>check it out!</strong>
            </Alert>
          </Collapse>
          <SuccessModal
            openModal={state.successModal}
            closeModal={closeSuccessModal}
            paymentCancellation={state.paymentCancellation}
            paymentCancellationMessage={state.paymentCancellationMessage}
          />
          {state.alertMessageTxt && (
            <TostMessage
              open={state.alertMessage}
              onClose={() =>
                setState({ alertMessage: false, alertMessageTxt: null })
              }
              title={state.alertMessageTxt}
              type={"info"}
            />
          )}
        </div>
      </Component>
    </>
  );
}

const Component = tw.div`
w-full
h-auto 
flex
flex-col
items-center
justify-center
`;
const paymentImput = `
w-full 
h-auto 
py-[15px]
px-4 
bg-white255 
text-black21 
rounded-lg
focus:outline-none 
border-[#ffffff]
shadow-[0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)]
focus:border-[black]
placeholder:text-[#757680]
`;

export default PaymentDetails;
