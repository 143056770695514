import React, { useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import Dialog from "@mui/material/Dialog";
import { Zoom } from "@mui/material";
import { PrimaryText } from "@components";
import "../../containerStyle.css";

function UserSuccessModal(props) {
  const { t } = useTranslation("common");
  const { errorMessage, isChange } = props;

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      status: "",
      message: "",
    }
  );

  useEffect(() => {
    if (isChange) {
      if (errorMessage) {
        setState({
          message: `${t("profile.modal.errMessage")} ${errorMessage?.name}`,
          status: `${t("profile.modal.error")}`,
        });
      } else {
        setState({
          message: `${t("profile.modal.successMessage")}`,
          status: `${t("profile.modal.success")}`,
        });
      }
    } else {
      setState({
        message: `${t("profile.modal.sorryMessage")}`,
        status: `${t("profile.modal.sorry")}`,
      });
    }
  }, [errorMessage, isChange, t]);

  const closeModal = () => {
    props.closeModal();
  };

  const Title = (e) => {
    return (
      <div className="flex items-center justify-center w-full h-auto mb-4">
        <PrimaryText className="text-2xl text-textColor2 font-bold font-[Cambay] text-center">
          {e.title}
        </PrimaryText>
      </div>
    );
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={() => closeModal()}
      TransitionComponent={Zoom}
      sx={{
        height: "90%",
        marginTop: "3%",
      }}
    >
      <Content>
        <Title title={state.status} />
        <Line />
        <div className="w-full h-auto p-4 flex flex-col gap-4 items-center justify-center">
          <PrimaryText className="text-textColor2 text-md leading-normal font-[Cambay]">
            {state.message}
          </PrimaryText>
          <button
            className="w-fit h-auto py-2 px-6 rounded-lg bg-[#E5E5E9]"
            onClick={() => closeModal()}
          >
            {t("profile.modal.ok")}
          </button>
        </div>
      </Content>
    </Dialog>
  );
}

const Content = tw.div`
w-auto
h-auto 
p-4
md:overflow-x-hidden
sm:overflow-x-hidden
overflow-x-auto
overflow-y-auto
custom-scroll
`;
const Line = tw.div`
w-full
h-[1.1px] 
bg-primary 
mt-2
`;

export default UserSuccessModal;
