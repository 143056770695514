import * as types from "./types";

const initialState = {
  footerData: [],
  privacyPolicuData: [],
  cancelPolicuData: [],
  termConditionData: [],
  aboutUsData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FOOTER_DATA: {
      return {
        ...state,
        footerData: action?.payload?.data?.data,
      };
    }
    case types.GET_PRIVACY_POLICY: {
      if (action?.payload?.status === 200) {
        return {
          ...state,
          privacyPolicuData: action?.payload?.data?.content,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case types.GET_CANCEL_POLICY: {
      if (action?.payload?.status === 200) {
        return {
          ...state,
          cancelPolicuData: action?.payload?.data?.content,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case types.GET_TERM_CONDITION: {
      if (action?.payload?.status === 200) {
        return {
          ...state,
          termConditionData: action?.payload?.data?.content,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case types.GET_ABOUT_US: {
      if (action?.payload?.status === 200) {
        return {
          ...state,
          aboutUsData: action?.payload?.data?.content,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
};

export default reducer;
