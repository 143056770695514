import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import CancelIcon from "@mui/icons-material/Cancel";
import { Zoom } from "@mui/material";

function SuccessModal(props) {
  const { t } = useTranslation("common");

  return (
    <Dialog
      open={props.openModal}
      onClose={props.closeModal}
      TransitionComponent={Zoom}
    >
      <div className="bg-gray-100 h-auto">
        <div className="bg-white py-2 md:mx-auto">
          {props?.paymentCancellation === "payment-failed-booking-cancel" ? (
            <div className=" w-full h-auto flex justify-center mx-auto my-2">
              {" "}
              <CancelIcon
                fontSize="large"
                sx={{ width: 60, height: 60, color: "rgb(255, 60, 73)" }}
              />{" "}
            </div>
          ) : (
            <svg
              viewBox="0 0 24 24"
              className="text-green-400 w-16 h-16 mx-auto my-4"
            >
              <path
                fill="currentColor"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
              ></path>
            </svg>
          )}
          <div className="text-center">
            <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
              {props?.paymentCancellation === "payment-failed-booking-stay"
                ? t("successModal.congrat")
                : props?.paymentCancellation === "payment-failed-booking-cancel"
                ? t("successModal.bookingCancell")
                : t("successModal.bookingConfirm")}
            </h3>
            <p className="text-gray-600 my-2 px-6">
              {props?.paymentCancellation === "payment-failed-booking-stay"
                ? t("successModal.comPayFacility")
                : props?.paymentCancellation === "payment-failed-booking-cancel"
                ? `${t("successModal.bookingcancellDue")} ${
                    props?.paymentCancellationMessage
                  }`
                : t("successModal.comProcess")}
            </p>
            <p className="text-gray-600 mb-2 px-6">
              {props?.paymentCancellation === "payment-failed-booking-stay"
                ? t("successModal.emailSent")
                : props?.paymentCancellation === "payment-failed-booking-cancel"
                ? t("successModal.cancellEmailSent")
                : t("successModal.confirmEmailSent")}
            </p>
            <div className="py-4 text-center">
              <button
                onClick={props.closeModal}
                className="px-6 bg-primary hover:bg-primary2 text-white font-semibold py-2 rounded-md"
              >
                {t("successModal.goBack")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SuccessModal;
