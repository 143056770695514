import React, { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import tw from "tailwind-styled-components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { PrimaryText, EmptyCartModal } from "@components";
import CheckOutForm from "./widgets/checkOutForm";
import Timer from "./widgets/timer";

function CheckOut() {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const paymentLoader = useSelector((state) => state.payment.payLoader);
  const addToCartData = useSelector((state) => state.bookings?.addToCartData);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      is_CartEmpty: false,
    }
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (addToCartData?.length) {
      setState({
        is_CartEmpty: false,
      });
    } else {
      setState({
        is_CartEmpty: true,
      });
    }
  }, [addToCartData]);

  const back = () => {
    navigate(-1);
    setState({
      is_CartEmpty: false,
    });
  };

  return (
    <>
      <Timer />
      <BackBtn onClick={() => back()} disabled={paymentLoader}>
        <ArrowBackIosNewIcon fontSize="small" sx={{ color: "white" }} />
        <div className="ml-[5px]">
          <PrimaryText className="text-textColor font-bold">
            {t("checkOut.back")}
          </PrimaryText>
        </div>
      </BackBtn>
      <HomeScreenContent>
        <CheckOutForm />
      </HomeScreenContent>
      <EmptyCartModal
        openModal={state.is_CartEmpty}
        closeModal={back}
        errorMessage={"Your cart is empty please go back"}
      />
    </>
  );
}

const BackBtn = tw.button`
w-auto
h-auto 
flex 
xl1:pl-9  pl-4
items-center
`;
const HomeScreenContent = tw.div`
w-full
h-auto 
flex
flex-col
px-4
justify-between
items-start
md:pt-[10px]
pt-[20px]
mb-[90px]
`;

export default CheckOut;
