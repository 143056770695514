import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { PrimaryText, CustomButton } from "@components";
import { set, get } from "@utils";

function ChangeLanguage() {
  const { i18n, t } = useTranslation("common");

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      language: "en",
    }
  );

  useEffect(() => {
    const selectLanguage = get("Language");
    if (selectLanguage !== null) {
      setState({ language: selectLanguage });
    }
  }, []);

  const countries = [
    { code: "GB", label: "English", language: "en" },
    { code: "FR", label: "France", language: "fr" },
    { code: "DE", label: "Germany", language: "ge" },
  ];

  const handleChange = (event) => {
    setState({ language: event.target.value });
  };

  const changeLanguage = () => {
    i18n.changeLanguage(state.language);
    set("Language", state.language);
  };

  return (
    <Root>
      <Line />
      <div className="h-[500px] w-full flex justify-start">
        <div className="h-auto sm:w-[350px] w-full flex flex-col">
          <div className="mb">
            <PrimaryText className="text-textColor">
              {t("setting.selectLanguage")}
            </PrimaryText>
          </div>
          <Select
            value={state.language}
            onChange={handleChange}
            size="medium"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ width: "100%", backgroundColor: "#e4e7eb75" }}
          >
            {countries.map((item, index) => (
              <MenuItem
                key={index}
                value={item.language}
                sx={{ marginTop: "5px", marginBottom: "5px" }}
                disabled={item.language === state.language}
              >
                <div className="w-full h-full py flex flex-row items-center gap-x-1">
                  <div className="w-[35px] h-full">
                    <img
                      className="w-[30px] h-full"
                      src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
                      alt="flag"
                    />
                  </div>
                  <PrimaryText>{item.label}</PrimaryText>
                </div>
              </MenuItem>
            ))}
          </Select>
          <div className="sm:w-[200px] w-full h-auto mt-10">
            <CustomButton
              onClick={() => changeLanguage()}
              value={t("setting.changeLanguage")}
            />
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = tw.div`
px-4
py-8
h-full
w-full
`;
const Line = tw.div`
w-full
h-[1.1px] 
bg-primary
my-2
`;

export default ChangeLanguage;
